export const addIdolToRow = (idols, idolToAdd, index) => {
  const isDuplicate = idols.find(idol => idol === idolToAdd);
  if (!isDuplicate) {
    idols.splice(index, 0, idolToAdd);
  }
  return idols;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  // saves in a variable the one that was dragged from the source position
  const [removed] = result.splice(startIndex, 1);
  // adds the one that was previously removed to the position where it was dropped
  result.splice(endIndex, 0, removed);

  return result;
};
