/**
 * Positions.
 * @typedef {Object} displayName
 * @property {string} leftSideName The name that will be displayed on the left side of the avatar.
 * @property {string} bottomName The name that will be displayed below the avatar.
 */


/**
 *  Names to be displayed based on pre defined combination
 * @param {string} name
 * @param {string} nameStyled
 * @param {string} nickname
 * @param {string} nickNameStyled
 * @param {string | number} nameDisplayType
 * @returns displayName
 */
const displayNameDto = (nameDisplayType, name, nameStyled, nickname, nickNameStyled) => {
  let displayName = {
    leftSideName: '',
    bottomName: 'No Data'
  };

  switch (Number(nameDisplayType)) {
    case 1:
      displayName = {
        leftSideName: nameStyled,
        bottomName: nickNameStyled
      };
      break;
    case 2:
      displayName = {
        leftSideName: nameStyled,
        bottomName: nickname
      };
      break;
    case 3:
      displayName = {
        leftSideName: nameStyled,
        bottomName: nameStyled
      };
      break;
    case 4:
      displayName = {
        leftSideName: nameStyled,
        bottomName: name
      };
      break;
    case 5:
      displayName = {
        leftSideName: name,
        bottomName: nickname
      };
      break;
    case 6:
      displayName = {
        leftSideName: name,
        bottomName: nickNameStyled
      };
      break;
    case 7:
      displayName = {
        leftSideName: name,
        bottomName: name
      };
      break;
    case 8:
      displayName = {
        leftSideName: name,
        bottomName: nameStyled
      };
      break;
    case 9:
      displayName = {
        leftSideName: nickNameStyled,
        bottomName: nameStyled
      };
      break;
    case 10:
      displayName = {
        leftSideName: nickNameStyled,
        bottomName: name
      };
      break;
    case 11:
      displayName = {
        leftSideName: nickNameStyled,
        bottomName: nickNameStyled
      };
      break;
    case 12:
      displayName = {
        leftSideName: nickNameStyled,
        bottomName: nickname
      };
      break;
    case 13:
      displayName = {
        leftSideName: nickname,
        bottomName: name
      };
      break;
    case 14:
      displayName = {
        leftSideName: nickname,
        bottomName: nameStyled
      };
      break;
    case 15:
      displayName = {
        leftSideName: nickname,
        bottomName: nickname
      };
      break;
    case 16:
      displayName = {
        leftSideName: nickname,
        bottomName: nickNameStyled
      };
      break;
    case 17:
      displayName = {
        leftSideName: nameStyled,
        bottomName: ''
      };
      break;
    case 18:
      displayName = {
        leftSideName: name,
        bottomName: ''
      };
      break;
    case 19:
      displayName = {
        leftSideName: nickNameStyled,
        bottomName: ''
      };
      break;
    case 20:
      displayName = {
        leftSideName: nickname,
        bottomName: ''
      };
      break;
    case 21:
      displayName = {
        leftSideName: '',
        bottomName: name
      };
      break;
    case 22:
      displayName = {
        leftSideName: '',
        bottomName: nameStyled
      };
      break;
    case 23:
      displayName = {
        leftSideName: '',
        bottomName: nickNameStyled
      };
      break;
    case 24:
      displayName = {
        leftSideName: '',
        bottomName: nickname
      };
      break;
    default:
      displayName = {
        leftSideName: '',
        bottomName: 'No Data'
      };
      break;
  }
  return {
    leftSideName: selectFirstName(displayName.leftSideName),
    bottomName: selectFirstName(displayName.bottomName)
  };
};

/**
 * Returns only the first name if there are multiple names
 *
 * @param {string} name
 * @returns {string} first name
 */
function selectFirstName(name) {
  if (!name.includes(',')) {
    return name;
  }
  return name.split(',')[0];
}

export default displayNameDto;
