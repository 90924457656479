import React from 'react';

import JapaneseCardInfo from './JapaneseCardInfo';
import KoreanCardInfo from './KoreanCardInfo';

const IdolCardInfo = (props) => {
  const { idol } = props;
  return (
    idol.type === 'J-pop'
      ? <JapaneseCardInfo idolData={idol} />
      : <KoreanCardInfo idolData={idol} />
  );
};

export default IdolCardInfo;
