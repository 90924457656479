import React from 'react';
import { Button, Form } from 'semantic-ui-react';

class RowHeader extends React.Component {
    state = {
      isEditing: false
    }

    componentDidMount() {
      const { row } = this.props;

      this.setState({
        rowName: row.name
      });
    }

    deleteRow = () => {
      const { row, deleteRow } = this.props;

      deleteRow(row.id);
    }

    handleNameChange = (e, { name, value }) => {
      const { rowName } = this.state;
      if (value.length <= 20) {
        this.setState({ rowName: value });
      } else {
        this.setState({ rowName });
      }
    }

    toggleEditing = () => {
      const { isEditing } = this.state;
      this.setState({
        isEditing: !isEditing
      });
    }

    onCancel = () => {
      const { row } = this.props;

      this.setState({ rowName: row.name, isEditing: false });
    }

    onSave = () => {
      const { updateRowMetadata, row } = this.props;
      const { isEditing, rowName } = this.state;

      const updatedRow = {
        ...row,
        name: rowName
      };
      updateRowMetadata(updatedRow);
      this.setState({
        isEditing: !isEditing
      });
    }

    render() {
      const { isEditing, rowName } = this.state;
      const { isEditable } = this.props;

      return (
        <div className="row-header">

          <div className="row-header-name">
            <div className="row-header-name-label">
              {
                !isEditing
                  ? <h3>{rowName}</h3>
                  : <Form.Input fluid placeholder="Row Name" name="name" value={rowName} onChange={this.handleNameChange} />
              }
            </div>

            {
              isEditable && (
              <div className="row-header-name-buttons">
                <Button.Group size="small">
                  {
                    !isEditing
                      ? <Button onClick={this.toggleEditing} basic size="mini" icon="edit" />
                      : (
                        <Button.Group size="mini">
                          <Button onClick={this.onSave} basic size="mini" icon="save" />)
                          <Button onClick={this.onCancel} basic size="mini" color="youtube" icon="remove circle" />
                        </Button.Group>
                      )
                }
                </Button.Group>

              </div>
              )
            }
          </div>

          {
            isEditable && (
            <div className="ranker-row-remove-row-button">
              <Button.Group size="small">
                <Button
                  size="small"
                  color="red"
                  icon="remove circle"
                  onClick={this.deleteRow}
                />
              </Button.Group>
            </div>
            )
          }
        </div>
      );
    }
}

export default RowHeader;
