import { firebase } from '../../firebase';

export const deleteRankerRows = async (ranker) => {
  const rankerId = ranker.metadata.id;

  return firebase.database().ref(`ranker-rows/${rankerId}`).set(null)
    .then((snapshot) => {
      // console.log('Ranker metadata', snapshot.val());
    //   return snapshot.val();
    })
    .catch((error) => {
      // console.log('Failed to delete ranker rows: ', error);
    });
};

export const updateRankerRows = async (newRankerData) => {
  const rankerId = newRankerData.metadata.id;
  const order = newRankerData.rowOrder;

  return firebase.database().ref(`ranker-rows/${rankerId}`).set(order)
    .then((snapshot) => {
      // console.log('Ranker metadata', snapshot.val());
    //   return snapshot.val();
    })
    .catch((error) => {
      // console.log('Failed to update ranker rows: ', error);
    });
};

export default updateRankerRows;
