import '../../../models/typings';


/**
 * Nickname in localized writing like Kanji or Hangul
 *
 * * %:% Separates the group/team name from group/team color
 * * - Separates the group from team data
 * * , Separates group information
 * @param {string} idolPosition ex: AKB48%:%#ef92b0%-%Team 4%:%#FFD300, STU48%:%#004ea2%-%null
 * @returns {positions} positions
 */
const positionsDto = (idolPosition) => {
  let position = idolPosition;
  if (!position) {
    position = 'Solo:null';
  }

  const groups = position.split(',');
  const positions = [];
  let groupName; let groupColor; let teamName; let teamColor;
  groups.forEach(group => {
    // Check if it has team and split information
    if (group.includes('%-%')) {
      const groupInfo = group.split('%-%')[0].trim();
      const teamInfo = group.split('%-%')[1].trim();

      [groupName, groupColor] = groupInfo.split('%:%');
      [teamName, teamColor] = teamInfo.split('%:%');
    } else {
      const groupInfo = group.split('%-%')[0].trim();

      [groupName, groupColor] = groupInfo.split('%:%');
      teamName = 'null';
      teamColor = 'null';
    }
    positions.push({
      groupName,
      groupColor,
      teamName,
      teamColor
    });
  });

  // console.log('Positions objects', positions[0]);
  return positions;
};

export default positionsDto;
