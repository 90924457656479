
import React from 'react';

const LoadingIdolSekai = ({ loadingClass, size }) => (
  <div className={loadingClass || ''}>
    <img alt="loader" className={`loader__image loading-${size}`} src="https://idolsekai.s3.eu-central-1.amazonaws.com/Assets/loading.png" />
  </div>
);

export default LoadingIdolSekai;
