import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  login, logout, startLoadUserData
} from 'actions/auth';
import configureStore from 'store/configureStore';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import AppRouter, { history } from './routers/AppRouter';
import './polyfills';
import 'normalize.css/normalize.css';
import './styles/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import { firebase } from './firebase/firebase';

import LoadingPage from './components/LoadingPage';

const googleAnalyticsTrackingId = 'UA-167149510-1';
ReactGA.initialize(googleAnalyticsTrackingId);


const store = configureStore();

toast.configure();

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);
let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('app'));
    hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById('app'));

firebase.auth().onAuthStateChanged((user) => {
  // console.log('Auth state changed: ', user, firebase);

  if (user) {
    ReactGA.set({
      userId: user.uid
      // any data that is relevant to the user session
      // that will be tracked with google analytics
    });
    store.dispatch(login(user.uid));
    // store.dispatch(updateUserData(user));
    store.dispatch(startLoadUserData(user));
    renderApp();
    if (history.location.pathname === '/login') {
      history.push('/');
    }
  } else {
    store.dispatch(logout());
    renderApp();
    // if (!history.location.pathname.includes('/ranker')) {
    //   // history.push('/');
    // }
  }
});
