import React, { createRef } from 'react';
import { Ref, Button, Icon } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { startLoadAllIdols } from 'actions/idol';
import { resetFilters } from 'actions/filters';
import selectIdols from '../../../selectors/idols';

import IdolListContent from './IdolListContent';
import IdolListFilters from './IdolListFilters';

export class IdolList extends React.Component {
    state = {
      firstVisible: 0,
      lastVisible: 15
    }

    contextRef = createRef();

    componentDidMount() {
      const { startLoadAllIdols } = this.props;
      startLoadAllIdols();
    }


    componentDidUpdate(prevProps, prevState) {
      const { filters, idols } = this.props;

      if (idols.length !== prevProps.idols.length || filters !== prevProps.filters) {
        this.contextRef.current.scrollTo(0, 0);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          firstVisible: 0,
          lastVisible: 15
        });
      }
    }

    addIdolsToList = () => {
      let { firstVisible, lastVisible } = this.state;
      const { idols } = this.props;

      lastVisible += 15;
      if (idols.length > 50) {
        firstVisible += 10;
      }
      this.updateVisibleIdols(firstVisible, lastVisible);
    }

    handleUpdate = (e, { calculations }) => {
      const { lastVisible } = this.state;
      const { idols } = this.props;

      // console.log('Calculations: ', calculations.bottomVisible, calculations.topVisible);
      if (calculations.bottomVisible && lastVisible < idols.length) {
        // console.log('Render Handle update', calculations, lastVisible);
        this.addIdolsToList();
      }

      if (calculations.topVisible) {
        // console.log('top', calculations.topVisible);
        // this.removeIdolsFromList();
      }
      // this.updateVisibleIdols(calculations.bottomVisible, calculations.topVisible);
    };

    removeIdolsFromList = () => {
      const { firstVisible, lastVisible } = this.state;

      const last = lastVisible - 15;
      const first = firstVisible - 10;
      this.updateVisibleIdols(first, last);
    }

    resetListContent = () => {
      const { resetFilters } = this.props;

      this.contextRef.current.scrollTo(0, 0);

      resetFilters();

      this.setState({
        firstVisible: 0,
        lastVisible: 15
      });
    }

    updateVisibleIdols = (firstVisibleIndex, lastVisibleIndex) => {
      const { idols } = this.props;
      let firstVisible = firstVisibleIndex;
      let lastVisible = lastVisibleIndex;

      if (lastVisible > idols.length) {
        lastVisible = idols.length;
      }
      if (firstVisible < 0) {
        firstVisible = 0;
      }

      this.setState({
        firstVisible,
        lastVisible
      });
    }

    render() {
      const { idols, loadingIdols } = this.props;
      const { lastVisible } = this.state;
      const visibleIdols = idols.slice(0, lastVisible);
      // console.log(`List idols: ${lastVisible - firstVisible} / ${idols.length}`);

      return (
        <div className="idol-list-container">

          <div className="idol-list-input">
            {/* <button
              type="button"
              className="button"
              onClick={startLoadAllIdols}
            >Load Idols
            </button> */}
            <IdolListFilters listIsLoading={loadingIdols} listIsEmpty={!visibleIdols.length} />
          </div>
          <Ref innerRef={this.contextRef}>
            <IdolListContent
              handleUpdate={this.handleUpdate}
              loadingIdols={loadingIdols}
              visibleIdols={visibleIdols}
            />
          </Ref>

          <div className="idol-list-load-more">
            <Button
              loading={loadingIdols}
              fluid
              size="small"
              color="twitter"
              type="button"
              className="button"
              onClick={this.resetListContent}
            >
              <Icon name="undo alternate" />Reset
            </Button>
          </div>
        </div>
      );
    }
}

// export default IdolList;

const mapStateToProps = (state) => {
  // console.log('State:', state);
  return {
    filters: state.filters,
    idols: selectIdols(state.idol.allIdols, state.filters),
    loadingIdols: state.idol.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLoadAllIdols: () => dispatch(startLoadAllIdols()),
  resetFilters: () => dispatch(resetFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(IdolList);
