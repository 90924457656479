import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import {
  startLoadUserRankers, addRow, startSaveRanker, updateRanker, startDeleteRanker
} from 'actions/ranker';
import RankerDnd from './RankerDnd';
import RankerFooter from './RankerFooter';
import createRow from '../../helpers/row/create-row';
import RankerHeader from './RankerHeader';


class Ranker extends React.Component {
  state = {}

  addRow = () => {
    const { addRow, userId, ranker: { metadata: { rowLimit = 5 }, rowOrder } } = this.props;

    if (rowOrder.length >= rowLimit) {
      toast.error(`Limit: ${rowLimit} rows `, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }
    const newRow = createRow(userId);

    addRow(newRow);
  }

  deleteRanker = () => {
    const { ranker, startDeleteRanker } = this.props;
    startDeleteRanker(ranker);
  }

  deleteRow = (rowId) => {
    const { ranker, updateRanker } = this.props;
    delete ranker.rows[rowId];
    ranker.rowOrder.splice(ranker.rowOrder.indexOf(rowId), 1);

    // console.log('Delete row: ', rowId);
    // console.log('Updated rows: ', ranker);
    updateRanker(ranker);
  }

  deleteIdol = (idolId, rowId) => {
    const { ranker, updateRanker } = this.props;
    if (!this.isEditable()) {
      return;
    }

    delete ranker.idols[idolId];

    const idolIndex = ranker.rows[rowId].idolIds.indexOf(idolId);
    ranker.rows[rowId].idolIds.splice(idolIndex, 1);

    // console.log('Remove idol: ', idolId, rowId);
    updateRanker(ranker);
  }

  isEditable = () => {
    const { history: { location: { pathname } } } = this.props;

    return pathname.indexOf('ranker') === -1;
  }

  saveRanker = () => {
    const { startSaveRanker, ranker, originalRankerData } = this.props;
    // console.log('Save Ranker', ranker, originalRankerData);
    startSaveRanker(originalRankerData, ranker);
  }

  updateRankerMetadata = (rankerMetadata) => {
    const { ranker, updateRanker } = this.props;

    const updatedRanker = {
      ...ranker,
      metadata: rankerMetadata
    };

    updateRanker(updatedRanker);
  }

  updateRowMetadata = (row) => {
    const { ranker, updateRanker } = this.props;

    const updatedRanker = {
      ...ranker,
      rows: {
        ...ranker.rows,
        [row.id]: row
      }
    };
    updateRanker(updatedRanker);
  }

  render() {
    const {
      deletingRanker, ranker, rankerOptions, savingRanker, selectedRanker
    } = this.props;
    const isEditable = this.isEditable();

    return (
      <div>

        {
          rankerOptions.showRankerLogo && (
          <div className="idolsekai-ranker-logo">
            <img height="75vh" src="/assets/logo.png" alt="IdolSekai" />
            <h3 style={{ alignSelf: 'center' }}><b>.com</b></h3>
          </div>
          )
        }


        <RankerHeader
          isEditable={isEditable}
          ranker={ranker}
          updateRankerMetadata={this.updateRankerMetadata}
        />

        <RankerDnd
          isEditable={isEditable}
          deleteIdol={this.deleteIdol}
          deleteRow={this.deleteRow}
          updateRowMetadata={this.updateRowMetadata}
          ranker={ranker}
          rankerOptions={rankerOptions}
        />

        {
          isEditable && selectedRanker && (
            <div className="ranker-footer-container" data-html2canvas-ignore>
              <div className="ranker-footer-add-row">
                <Button
                  circular
                  style={{ backgroundColor: '#55acee' }}
                  icon="add circle"
                  size="huge"
                  onClick={this.addRow}
                />
              </div>

              <RankerFooter
                deleteRanker={this.deleteRanker}
                deletingRanker={deletingRanker}
                rankerId={ranker.metadata.id}
                saveRanker={this.saveRanker}
                savingRanker={savingRanker}
              />
            </div>
          )
        }

      </div>

    );
  }
}


// export default Ranker;

const mapStateToProps = (state) => {
  // console.log('[Ranker] State Rankers Reducer: ', state);
  return {
    deletingRanker: state.rankers.deletingRanker,
    loading: state.rankers.loading,
    rows: state.rankers.rows,
    savingRanker: state.rankers.savingRanker,
    selectedRanker: state.rankers.selectedRanker,
    originalRankerData: state.rankers.originalRankerData,
    rankerOptions: state.rankers.options,
    userId: state.auth.uid
  };
};

const mapDispatchToProps = (dispatch) => ({
  addRow: (row) => dispatch(addRow(row)),
  startDeleteRanker: (ranker) => dispatch(startDeleteRanker(ranker)),
  startLoadUserRankers: () => dispatch(startLoadUserRankers()),
  startSaveRanker: (originalRankerData, ranker) => dispatch(
    startSaveRanker(originalRankerData, ranker)
  ),
  updateRanker: (ranker) => dispatch(updateRanker(ranker))
});

export default connect(mapStateToProps, mapDispatchToProps)(Ranker);
