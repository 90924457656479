import React from 'react';
import uuid from 'uuid/v4';

import { connect } from 'react-redux';
import {
  List, TextArea
} from 'semantic-ui-react';
import { buildIdolsData } from 'mappers';
import IdolAvatar from './idol/IdolAvatar/IdolAvatar';
import IdolCardInfo from './idol/IdolCard/IdolCardInfo';


class AdminPage extends React.Component {
  state = {
    idols: null
  }

  onTextChange = (e) => {
    const textIdols = JSON.parse(e.target.value).idols;
    let loadedIdols = {};

    for (const idolId in textIdols) {
      loadedIdols = {
        ...loadedIdols,
        [textIdols[idolId].ID]: textIdols[idolId]
      };
    }

    this.setState({
      idols: buildIdolsData(loadedIdols).idols
    });
  };

  render() {
    const { isAdmin } = this.props;
    const { idols } = this.state;
    // console.log('Is admin ', isAdmin, idols);

    return (
      <div>
        {
          isAdmin && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                Paste idol.json content here <br />
                <TextArea style={{ height: '100%', width: '75vw' }} onChange={this.onTextChange} placeholder="" />
              </div>
              <div>


                {
                  idols
                    ? (
                      <List divided verticalAlign="middle">

                        {
                          idols.length > 0
                            && (
                              idols.map((idol, index) => {
                                return (
                                  <List.Item key={uuid()}>
                                    <div className="idol-list-item">
                                      <IdolAvatar idolData={idol} />
                                      <IdolCardInfo idol={idol} />
                                    </div>
                                  </List.Item>
                                );
                              })
                            )
                        }
                      </List>
                    )
                    : <h2>No valid idols available</h2>

                }
              </div>
            </div>
          )}

      </div>
    );
  }
}

// export default AdminPage;
const mapStateToProps = (state) => {
  // console.log('State:', state);
  return {
    isAdmin: state.auth.user.isAdmin
  };
};

export default connect(mapStateToProps, null)(AdminPage);
