/**
 * Wiki url
 * @param {string} wiki
 * @returns string
 */
const wikiDto = (wiki) => {
  return wiki || null;
};

export default wikiDto;
