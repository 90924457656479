import React from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

import releaseNotes from '../../../releaseNotes.md';

export class News extends React.Component {
    state = { isOpen: false }

    componentDidMount = () => {
      const hasCookie = localStorage.getItem('idolsekai-version');

      if (hasCookie && hasCookie === process.env.IDOLSEKAI_VERSION) {
        this.setState({ isOpen: false });
        return;
      }

      this.setState({ isOpen: true });
    };

    onOpen = () => {
      this.setState({ isOpen: true });
    };

    onClose = () => {
      localStorage.setItem('idolsekai-version', process.env.IDOLSEKAI_VERSION);
      this.setState({ isOpen: false });
    };

    render() {
      const { isOpen } = this.state;

      return (
        <Modal
          open={isOpen}
          onClose={this.onClose}
          trigger={<button onClick={this.onOpen} type="button" className="button button--link"> <Icon fitted name="exclamation" />    News</button>}
          closeIcon
        >
          <Modal.Header>News!</Modal.Header>
          <Modal.Content>
            <ReactMarkdown
              source={releaseNotes}
            />
          </Modal.Content>

        </Modal>
      );
    }
}

export default News;
