import uuid from 'uuid/v4';
import moment from 'moment';


const createRow = (userId) => {
  const generatedId = uuid();
  const newRow = {
    id: generatedId,
    name: 'My Row',
    created_on: moment().format(),
    updated_on: moment().format(),
    user_id: userId,
    idolIds: []
  };
  return newRow;
};

export default createRow;
