import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

import { startLogin, startLogout } from 'actions/auth';
import { selectRanker, updateRanker } from 'actions/ranker';
import Help from './modals/Help';
import Login from './modals/Login';
import News from './modals/News';

export const Header = ({
  history, selectRanker, startLogin, startLogout, updateRanker, user
}) => (
  <header className="idolsekai-header">
    {/* <div className="content-container">
      <div className="header__content">
        <Link className="header__title" to="/dashboard">
          <h1>Idol Sekai  {user.displayName}</h1>
        </Link>
        <div className="idolsekai-dark-blue ">
        <button type="button" className="button button--link" onClick={startLogout}>Logout</button>
        </div>
      </div>
    </div> */}


    <div className="flex-row-container full-height">
      <div style={{
        display: 'flex', alignItems: 'center', flexBasis: '20%', paddingLeft: '3%'
      }}
      >
        <Link className="header__title" to="/">
          <img height="75vh" src="/assets/logo.png" alt="IdolSekai" />

        </Link>
        <h3><b>beta</b></h3>

      </div>
      <div
        style={{
          display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexBasis: '80%'
        }}

      >
        <div style={{ marginRight: '3%' }} className="idolsekai-dark-blue ">
          <News />
        </div>

        <div style={{ marginRight: '3%' }} className="idolsekai-dark-blue ">
          <Help />
        </div>

        <div style={{ marginRight: '3%', backgroundColor: 'crimson' }}>

          {
            user
              ? <button type="button" className="button button--link" onClick={() => { startLogout(); history.push('/'); }}> <Icon fitted name="sign-out" />    Logout</button>
              : <Login startLogin={startLogin} />
            }

        </div>


      </div>
    </div>
  </header>
);

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLogin: () => dispatch(startLogin()),
  startLogout: () => dispatch(startLogout()),
  selectRanker: (ranker) => dispatch(selectRanker(ranker)),
  updateRanker: (ranker) => dispatch(updateRanker(ranker))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
