// SET_TEXT_FILTER
// eslint-disable-next-line import/prefer-default-export
export const setTextFilter = (text = '') => ({
  type: 'SET_TEXT_FILTER',
  text
});

export const toggleCheckbox = (checkboxId) => ({
  type: 'TOGGLE_CHECKBOX',
  checkboxId
});

export const resetFilters = () => ({
  type: 'RESET_FILTERS'
});

export const updateFilters = (filters) => ({
  type: 'UPDATE_FILTER',
  filters
});

export const updateSortByValue = (value) => ({
  type: 'UPDATE_SORT_BY_VALUE',
  value
});

export const updateSortByDirection = (direction) => ({
  type: 'UPDATE_SORT_BY_DIRECTION',
  direction
});
