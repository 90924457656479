import { firebase } from '../../firebase';


export const deleteRow = async (rowId) => {
  return firebase.database().ref(`rows/${rowId}`).set(null)
    .then((snapshot) => {
      // console.log('Deleted row:', rowId);
      // const updatedRowId = snapshot.key;
      // return updatedRowId;
    })
    .catch((error) => {
      // console.log('Failed to delete row: ', error);
    });
};

export default deleteRow;
