import React from 'react';
import { Button, Icon, Form } from 'semantic-ui-react';


class RankerHeader extends React.Component {
    state = {
      isEditing: false
    }

    componentDidMount() {
      const { ranker } = this.props;

      this.setState({
        rankerName: ranker.metadata.name
      });
    }


    handleNameChange = (e, { name, value }) => {
      const { rankerName } = this.state;
      if (value.length <= 30) {
        this.setState({ rankerName: value });
      } else {
        this.setState({ rankerName });
      }
    }

    toggleEditing = () => {
      const { isEditing } = this.state;
      this.setState({
        isEditing: !isEditing
      });
    }

    onCancel = () => {
      const { ranker } = this.props;

      this.setState({ rankerName: ranker.metadata.name, isEditing: false });
    }

    onSave = () => {
      const { updateRankerMetadata, ranker } = this.props;
      const { isEditing, rankerName } = this.state;

      const updatedMetadata = {
        ...ranker.metadata,
        name: rankerName
      };
      updateRankerMetadata(updatedMetadata);
      this.setState({
        isEditing: !isEditing
      });
    }

    render() {
      const { isEditing, rankerName } = this.state;
      const { isEditable } = this.props;

      return (

        <div className="ranker-header">
          <div className="ranker-header-name">
            <div className="ranker-header-name-label ">
              {
                !isEditing
                  ? <h2 className="idolsekai-default-font">{rankerName}</h2>
                  : <Form.Input placeholder="Ranker Name" name="name" value={rankerName} onChange={this.handleNameChange} />
              }
            </div>

            {
              isEditable
              && (
              <div className="ranker-header-name-buttons">
                <Button.Group size="mini">
                  {
                    !isEditing
                      ? <Button onClick={this.toggleEditing} basic size="medium" icon="edit" />
                      : (
                        <Button.Group size="mini">
                          <Button onClick={this.onSave} basic size="medium" icon="save" />
                          <Button onClick={this.onCancel} basic size="mini" style={{ backgroundColor: '#c00' }}><Icon name="remove circle" /></Button>
                        </Button.Group>
                      )

                }

                </Button.Group>
              </div>
              )
            }

          </div>
        </div>

      );
    }
}

export default RankerHeader;
