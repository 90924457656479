import { buildIdolsData } from 'mappers';
import idolDto from '../../../core/mappers/idol/idolDto';
import { firebase } from '../../firebase';

export const loadAllIdols = async () => {
  return firebase.database().ref('idols').once('value')
    .then((snapshot) => {
      // console.log('Load All Idols: ', snapshot, snapshot.val());
      return buildIdolsData(snapshot.val());
    })
    .catch((error) => {
      console.log('Failed Load All Idols: ', error);
    });
};

export const loadIdolMetadata = async (id) => {
  return firebase.database().ref(`idols/${id}`).once('value')
    .then((snapshot) => {
      const idolMetadata = idolDto(snapshot.val());
      // console.log('Mapper idol metadata', idolMetadata);
      return idolMetadata;
    })
    .catch((error) => {
      // console.log('Failed to load idol: ', error);
    });
};

export default loadIdolMetadata;
