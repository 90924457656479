/* eslint-disable no-param-reassign */
/* eslint-disable-next-line no-return-assign */
import React from 'react';
import { Image } from 'semantic-ui-react';
import uuid from 'uuid/v4';
import { removeSpecialCharacters } from 'helpers';

import IdolCardDetails from './IdolCardDetails';

const logosFolder = 'https://idolsekai.s3.eu-central-1.amazonaws.com/Logos';


export const KoreanCardInfo = ({
  idolData,
  idolData: {
    positions
  }
}) => (

  <div className="idol-list-item-info-container">

    <div className="idol-list-item-group-container">
      {
          positions.map((position) => {
            return (
              <div key={uuid()} className="idol-list-item-group-kpop">
                <div className="idol-list-item-group-logo idolsekai-shadow-soft">
                  <Image
                    fluid
                    // eslint-disable-next-line no-return-assign
                    onError={element => element.target.src = 'https://idolsekai.s3.eu-central-1.amazonaws.com/Logos/group_default.jpg'}
                    src={encodeURI(`${logosFolder}/${removeSpecialCharacters(position.groupName)}Logo.jpg`)}
                  />
                </div>

                <div
                  style={{
                    border: `2px solid ${position.groupColor !== 'null' && position.groupColor !== undefined ? position.teamColor : '#e5e5e5'}`,
                    backgroundColor: `${position.groupColor !== 'null' && position.groupColor !== undefined ? position.teamColor : '#e5e5e5'}`,
                    color: 'white',
                    fontWeight: 800,
                    textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
                  }}
                  className="idol-list-item-group-team idolsekai-shadow-soft"
                >
                  {position.groupName}
                </div>
              </div>
            );
          })
        }
    </div>

    <IdolCardDetails idolData={idolData} />

  </div>
);


export default KoreanCardInfo;
