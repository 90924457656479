import moment from 'moment';

import { updateRowIdols } from './row-idols';
import { loadRowMetadata, updateRowMetadata, isNewRow } from './row-metadata';
import { loadRowIdolIds } from './row-idol-ids';

export const loadRowsMetadata = async (rowsIds) => {
  const rowsMetadata = [];

  for (const id of rowsIds) {
    const rowMetadata = await loadRowMetadata(id);
    const rowIdolIds = await loadRowIdolIds(id);
    if (rowMetadata) {
      rowsMetadata.push({
        ...rowMetadata,
        idolIds: rowIdolIds
      });
    }
  }

  return rowsMetadata;
};

export const updateRowsMetadata = async (rankerData) => {
  const rowsMetadata = rankerData.rows;
  const rankerIdols = rankerData.idols;

  for (const id of Object.keys(rowsMetadata)) {
    const normalizedMetadata = await normalizeUpdateData(rowsMetadata[id]);
    await updateRowMetadata(normalizedMetadata);
    await updateRowIdols(id, rowsMetadata[id].idolIds, rankerIdols);
  }
};

async function normalizeUpdateData(rowMetadata) {
  const isNew = await isNewRow(rowMetadata.id);
  if (!isNew) {
    return {
      created_on: moment().format(),
      id: rowMetadata.id,
      name: rowMetadata.name,
      updated_on: moment().format(),
      user_id: rowMetadata.user_id
    };
  }
  return {
    created_on: rowMetadata.created_on,
    id: rowMetadata.id,
    name: rowMetadata.name,
    updated_on: moment().format(),
    user_id: rowMetadata.user_id
  };
}
