import React from 'react';
import { connect } from 'react-redux';
import {
  updateRankerOptions
} from 'actions/ranker';
import {
  Checkbox, Popup
} from 'semantic-ui-react';

class RankerOptions extends React.Component {
    toggle = ({ target }) => {
      const { options, updateRankerOptions } = this.props;
      //   console.log('Toggle:', target.id);

      updateRankerOptions({
        ...options,
        [target.id]: !options[target.id]
      });
    };

    render() {
      const { options: { previewMode, showRowNames, showIdolPosition } } = this.props;
      return (
        <div style={{
          display: 'flex', justifyContent: 'center', flexDirection: 'column', marginLeft: '8%', marginTop: '20%'
        }}
        >
          <div>
            <div className="flex-row-container" style={{ justifyContent: 'center' }}>
              <Checkbox toggle id="showRowNames" onChange={this.toggle} checked={showRowNames} />
            </div>
            <div>
              <div className="isk-small-font" style={{ textAlign: 'center' }}>
                  Row name
              </div>
            </div>
          </div>

          <div style={{ marginTop: '5%' }}>
            <div className="flex-row-container" style={{ justifyContent: 'center' }}>
              <Checkbox toggle id="showIdolPosition" onChange={this.toggle} checked={showIdolPosition} />
            </div>
            <div>
              <div className="isk-small-font" style={{ textAlign: 'center' }}>
                  Idol Position
              </div>
            </div>
          </div>

          <div style={{ marginTop: '5%' }}>
            <Popup
              content="When enabled, most drag movements will not work correctly"
              style={{ color: 'red', fontWeight: 'bold' }}
              trigger={(
                <div>
                  <div className="flex-row-container" style={{ justifyContent: 'center' }}>
                    <Checkbox
                      toggle
                    // label="Make all idols visible"
                      id="previewMode"
                      onChange={this.toggle}
                      checked={previewMode}
                    />
                  </div>
                  <div className="isk-small-font" style={{ textAlign: 'center', marginBottom: '5%' }}>
                  Make all idols visible
                  </div>
                </div>

              )}
            />
          </div>
        </div>
      );
    }
}

// export default RankerOptions;


const mapStateToProps = (state) => {
  // console.log('[Ranker] State Rankers Reducer: ', state);
  return {
    options: state.rankers.options
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateRankerOptions: (options) => dispatch(updateRankerOptions(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(RankerOptions);
