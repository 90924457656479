import { firebase } from '../../firebase';

export const createRanker = async (rankerId, rankerData) => {
  return firebase.database().ref(`rankers/${rankerId}`).set(rankerData)
    .then((snapshot) => {
      // console.log('Created ranker:', rankerId);
      // const updatedRowId = snapshot.key;
      // return updatedRowId;
    })
    .catch((error) => {
      // console.log('Failed to create ranker: ', error);
    });
};

export default createRanker;
