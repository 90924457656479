import React from 'react';
import { Image, List, Icon } from 'semantic-ui-react';


const Credits = () => {
  return (
    <div className="credits-container isk-xsmall-font">
      <List>
        <List.Item>
          <Image avatar src="/assets//credits/spnkdevAvatar.png" />
          <List.Content>
            <List.Header><a href="https://www.twitter.com/spnkdev" alt="spnkdev twitter" target="_blank" rel="noopener noreferrer">@spnkdev</a>
            </List.Header>
        Main Developer
          </List.Content>
        </List.Item>
        <List.Item>
          <Image avatar src="/assets//credits/mechapedeAvatar.jpg" />
          <List.Content>
            <List.Header>@mechapede</List.Header>
        Data Mining
          </List.Content>
        </List.Item>
        <List.Item>
          <Image avatar src="/assets//credits/lunaAvatar.png" />
          <List.Content>
            <List.Header href="https://www.instagram.com/lunart_ig/" alt="luna instagram" target="_blank" rel="noopener noreferrer">@Luna</List.Header>
        Web Designer
          </List.Content>
        </List.Item>
        <List.Item>
          <Image avatar src="/assets//credits/artisteeAvatar.png" />
          <List.Content>
            <List.Header href="https://www.instagram.com/pabogoyani/" alt="luna instagram" target="_blank" rel="noopener noreferrer">@ArtisTee</List.Header>
        Logo Designer
          </List.Content>
        </List.Item>
      </List>

      <div style={{ textAlign: 'center' }}>

        <div>
          <a
            href="https://docs.google.com/forms/d/1Qmi28SoYrb4sFmaMUz0RRygXKCNclfGbTYTtiR9PFbU/edit"
            alt="idolsekai feedback"
            target="_blank"
            rel="noopener noreferrer"
          ><Icon name="wordpress forms" />Give us feedback
          </a>
        </div>
        <br />
        <div>
          <a
            href="https://docs.google.com/forms/d/1sR6w8fSKhYJTMWYRZEnfbvBgrISciHbcrf9Tj_P7RXM/viewform?edit_requested=true"
            alt="idolsekai feedback"
            target="_blank"
            rel="noopener noreferrer"
          >Submit missing idols!
          </a>
        </div>

      </div>
    </div>
  );
};

export default Credits;
