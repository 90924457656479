import { firebase } from '../../firebase';

export const deleteUserRanker = async (ranker, userId) => {
  const rankerId = ranker.metadata.id;

  return firebase.database().ref(`user-rankers/${userId}/${rankerId}`).set(null)
    .then((snapshot) => {
      // console.log('Ranker metadata', rankerId, snapshot.val());
    //   return snapshot.val();
    })
    .catch((error) => {
      // console.log('Failed to delete user ranker: ', error);
    });
};

export const createUserRanker = async (userId, rankerId) => {
  return firebase.database().ref(`user-rankers/${userId}`).update({ [rankerId]: true })
    .then((snapshot) => {
      // console.log('Added new ranker to user-rankers', userId, rankerId);
    })
    .catch((error) => {
      // console.log('Failed to add new ranker to user-rankers: ', error);
    });
};

export const loadUserRankerIds = async (userId) => {
  return firebase.database().ref(`user-rankers/${userId}`).once('value')
    .then((snapshot) => {
      const rankerIds = [];
      snapshot.forEach(ranker => {
        rankerIds.push(ranker.key);
      });
      // console.log('User Ranker Ids:', snapshot.val(), rankerIds);
      return rankerIds;
    })
    .catch((error) => {
      // console.log('Failed User Rankers Load: ', error);
    });
};

export default deleteUserRanker;
