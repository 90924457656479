/**
 * Gender
 * * Ex: Male
 * @param {string} gender
 * @returns string
 */
const genderDto = (gender) => {
  return gender || null;
};

export default genderDto;
