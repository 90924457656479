import moment from 'moment';
import uuid from 'uuid/v4';
import { firebase } from '../../../firebase/firebase';
import {
  createRankerData, deleteRankerData, loadRankerData, loadUserRankers, saveRankerData
} from '../../../firebase/index';


export const createRankerSuccess = (newRanker) => ({
  type: 'CREATE_RANKER_SUCCESS',
  newRanker
});

export const deleteRankerSuccess = () => ({
  type: 'DELETE_RANKER_SUCCESS'
});

export const deleteRankerFail = () => ({
  type: 'DELETE_RANKER_FAIL'
});

export const loadUserRankersSuccess = (rankers) => ({
  type: 'LOAD_USER_RANKERS_SUCCESS',
  rankers
});

export const loadRowsFail = (error) => ({
  type: 'LOAD_ROWS_FAIL',
  error
});

export const loadRowsSuccess = (rowsData) => ({
  type: 'LOAD_ROWS_SUCCESS',
  rowsData
});

export const selectRanker = (ranker) => ({
  type: 'SELECT_RANKER',
  ranker
});

export const selectRankerDemo = () => ({
  type: 'SELECT_RANKER_DEMO'
});

export const saveRankerSuccess = () => ({
  type: 'SAVE_RANKER_SUCCESS'
});

export const saveRankerFail = () => ({
  type: 'SAVE_RANKER_FAIL'
});

export const addRow = (row) => ({
  type: 'ADD_ROW',
  row
});

export const startLoadRows = (rankerId) => {
  return (dispatch) => {
    dispatch({ type: 'LOAD_ROWS' });

    loadRankerData(rankerId)
      .then((rowsData) => {
        // console.log('Final Test data', rowsData);
        dispatch(loadRowsSuccess(rowsData));
      })
      .catch((error) => dispatch(loadRowsFail(`Failed to load rows data: ${error}`)));
  };
};

export const startLoadUserRankers = () => {
  return (dispatch) => {
    dispatch({ type: 'LOAD_USER_RANKERS' });

    const userId = firebase.auth().currentUser.uid;
    loadUserRankers(userId)
      .then((rankers) => dispatch(loadUserRankersSuccess(rankers)))
      .catch((error) => dispatch({ type: 'LOAD_USER_RANKERS_FAIL' }));
  };
};

export const startCreateRanker = (rankerLimit) => {
  return (dispatch) => {
    dispatch({ type: 'CREATE_RANKER' });

    const userId = firebase.auth().currentUser.uid;
    const rankerId = uuid();
    const newRanker = {
      id: rankerId,
      idolsPerRowLimit: 16,
      rowLimit: 5,
      user_id: userId,
      name: `Ranker ${moment().format('LTS')}`,
      created_on: moment().format(),
      updated_on: moment().format()
    };

    createRankerData(userId, rankerId, newRanker, rankerLimit)
      .then(() => dispatch(startLoadUserRankers()))
      .catch((error) => dispatch({ type: 'CREATE_RANKER_FAIL' }));
  };
};

export const startSaveRanker = (originalRankerData, rankerData) => {
  return (dispatch) => {
    dispatch({ type: 'SAVE_RANKER' });

    // saveRankerData(originalRankerData, rankerData);

    saveRankerData(originalRankerData, rankerData)
      .then(() => { dispatch(saveRankerSuccess()); dispatch(startLoadUserRankers()); })
      .catch((error) => dispatch(saveRankerFail(`Failed to save ranker data: ${error}`)));
  };
};

export const startDeleteRanker = (ranker) => {
  return (dispatch) => {
    dispatch({ type: 'DELETE_RANKER' });

    const userId = firebase.auth().currentUser.uid;

    deleteRankerData(ranker, userId)
      .then(() => {
        dispatch(deleteRankerSuccess());
        dispatch(startLoadUserRankers());
      })
      .catch((error) => dispatch(deleteRankerFail(`Failed to delete ranker: ${error}`)));
  };
};

export const updateRanker = (rankerData) => ({
  type: 'UPDATE_RANKER',
  rankerData
});

export const updateRankerOption = (option, value) => ({
  type: 'UPDATE_RANKER_OPTION',
  option,
  value
});

export const updateRankerOptions = (rankerOptions) => ({
  type: 'UPDATE_RANKER_OPTIONS',
  rankerOptions
});
