import { firebase } from '../../firebase';

export const loadRankerRowIds = async (rankerId) => {
  return firebase.database().ref(`ranker-rows/${rankerId}`).once('value')
    .then((snapshot) => {
      // console.log('Loaded ranker row ids', snapshot.val());
      return snapshot.val() || [];
    })
    .catch((error) => {
      // console.log('Failed to load ranker rows ids: ', error);
    });
};

export default loadRankerRowIds;
