import React from 'react';
import { connect } from 'react-redux';

import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import IdolAvatar from './IdolAvatar';


const Container = styled.div`
  // border: 1px solid lightgrey;
  // border-radius: 10px;
  padding: 4px;
  // margin-bottom: 8px;
  background-color: ${props => (props.isDragging ? 'lightgreen' : 'white')}
`;


class IdolAvatarDraggable extends React.Component {
  getDraggableId = () => {
    const { generatedIdolId, idol } = this.props;
    return generatedIdolId || idol.id;
  }

  onDoubleClick = () => {
    const { generatedIdolId, rowId, deleteIdol } = this.props;
    // console.log('Double click', generatedIdolId, rowId);
    deleteIdol(generatedIdolId, rowId);
  }

  render() {
    const {
      loadingIdolList, idol, index, context, positionInRow
    } = this.props;

    return (
      <div onDoubleClick={this.onDoubleClick}>
        <Draggable
          isDragDisabled={loadingIdolList}
          draggableId={this.getDraggableId()}
          index={index}
          context={context}
          positionInRow={context === 'ranker-row' ? positionInRow : null}
        >
          {(provided, snapshot) => (
            <Container
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
            >

              <IdolAvatar
                idolData={idol}
                positionInRow={positionInRow}
              />

            </Container>

            // Alternative to styled components
            // <TaskContainer provided={provided} idol={idol}/>

          )}
        </Draggable>
      </div>
    );
  }
}

// export default IdolAvatarDraggable;

const mapStateToProps = (state) => {
  // console.log('[Ranker] State Draggable Reducer: ', state);
  return {
    loadingIdolList: state.idol.loading
  };
};


export default connect(mapStateToProps, null)(IdolAvatarDraggable);
