import React from 'react';

const getFont = (groupName) => {
  if (groupName.includes('48')) {
    return 'idolsekai-akb-font';
  }
  return 'idolsekai-default-font group-label-default-font-size';
};

const getFontSize = (groupName) => {
  // eslint-disable-next-line prefer-destructuring
  const length = groupName.length;
  let fontSize = '';
  if (length >= 12) {
    fontSize = '0.7vw';
  }
  if (length >= 17) {
    fontSize = '0.5vw';
  }

  return fontSize;
};

const AvatarGroupLabel = ({
  gender, group, groupPositionIndex, positions, type, getBackgroundColor, getPortraitBackgroundUrl
}) => {
  return (

  // There is 2 level of divs with 2 backgrounds to go around an issue when generating screenshots
  // On a previous implementation, the background url and gradient/color were declared
  // on the same div, on the background string.
  // Html2Canvas was not able to handle different types on the same string
  // so its now broken into 2 divs.
  // The top div has the background color/gradient and
  // the bottom one will have an image url that will cover the gradient/color
  // of the parent if the image exists. Previous background string:
  /*
    background: `url(${getPortraitBackgroundUrl(group, type)}) no-repeat center center,
${getBackgroundColor(gender, positions, 'groupLabel', groupPositionIndex)} no-repeat center center`
  */

    <div style={{
      background: `${getBackgroundColor(gender, positions, 'groupLabel', groupPositionIndex)} no-repeat center center`,
      backgroundSize: 'cover'
    }}
    >

      <div
        key={group}
        className={`${getFont(group)}`}
        style={{
          background: `url(${getPortraitBackgroundUrl(group, type)}) no-repeat center center`,
          backgroundSize: 'cover',
          color: 'white',
          marginLeft: '1px',
          padding: '5px',
          fontWeight: 800,
          textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
          fontSize: getFontSize(group)
        }}
      >{group}
      </div>
    </div>

  );
};

export default AvatarGroupLabel;
