import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Header from '../components/Header';

export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    component={(props) => (
      <div>
        <Header {...props} />
        <Component {...props} />
      </div>

    )}
  />
);

const mapStateToProps = (state) => {
  // console.log('Auth State Private:', state.auth);
  return {
    isAuthenticated: !!state.auth.uid
  };
};

export default connect(mapStateToProps)(PrivateRoute);
