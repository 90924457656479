import {
  createStore, combineReducers, applyMiddleware, compose
} from 'redux';
import thunk from 'redux-thunk';
import authReducer from 'reducers/auth';
import idolReducer from 'reducers/idol';
import filtersReducer from 'reducers/filters';
import rankersReducer from 'reducers/ranker';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      idol: idolReducer,
      filters: filtersReducer,
      rankers: rankersReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};
