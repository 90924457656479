import { sortByType } from 'helpers';

const filtersExist = (filters) => {
  const selectedFilters = [];
  for (const filter in filters) {
    if (filters[filter].length) {
      selectedFilters.push(filter);
    }
  }
  return selectedFilters;
};

const idolBelongsToGroups = (positions, filter) => {
  let belongs = true;
  const groupNames = new Set();
  for (const position of positions) {
    groupNames.add(position.groupName.toLowerCase());
  }
  for (const groupName of filter) {
    if (!groupNames.has(groupName)) {
      belongs = false;
    }
  }

  return belongs;
};

const idolBelongsToTeam = (positions, filter) => {
  let belongs = true;
  const teamNames = new Set();
  for (const position of positions) {
    teamNames.add(position.teamName.toLowerCase());
  }
  for (const teamName of filter) {
    if (!teamNames.has(teamName)) {
      belongs = false;
    }
  }

  return belongs;
};

const idolNameMatches = (idol, text) => {
  if (idol.name.toLowerCase().includes(text)) {
    return true;
  }
  if (idol.nameStyled.toLowerCase().includes(text)) {
    return true;
  }
  if (idol.nickname && idol.nickname.toLowerCase().includes(text)) {
    return true;
  }

  if (idol.nicknameStyled && idol.nicknameStyled.includes(text)) {
    return true;
  }
  return false;
};

const idolMatchesFilters = (idol, text, filters) => {
  let ageMatch = true;
  let birthPlaceMatch = true;
  let countryMatch = true;
  let genderMatch = true;
  let groupMatch = true;
  let statusMatch = true;
  let teamMatch = true;
  let typeMatch = true;

  if (filters.age.length && !filters.age.includes(idol.age.toString())) {
    ageMatch = false;
  }

  if (filters.birthPlace.length
      && (!idol.birthPlace || !filters.birthPlace.includes(idol.birthPlace.toLowerCase()))) {
    birthPlaceMatch = false;
  }

  if (filters.country.length
    && (!idol.country || !filters.country.includes(idol.country.toLowerCase()))) {
    countryMatch = false;
  }

  if (filters.gender.length
    && (!idol.gender || !filters.gender.includes(idol.gender.toLowerCase()))) {
    genderMatch = false;
  }

  if (filters.group.length && !idolBelongsToGroups(idol.positions, filters.group)) {
    groupMatch = false;
  }

  if (filters.status.length
    && (!idol.status || !filters.status.includes(idol.status.toLowerCase()))) {
    statusMatch = false;
  }

  if (filters.team.length && !idolBelongsToTeam(idol.positions, filters.team)) {
    teamMatch = false;
  }

  if (filters.type.length && !filters.type.includes(idol.type.toLowerCase())) {
    typeMatch = false;
  }

  // const nameToFilter = idol.Type === 'J-pop' ? idol.name :
  /*
  console.log(ageMatch,
    birthPlaceMatch, countryMatch, genderMatch, groupMatch, statusMatch, teamMatch, typeMatch,
    idol.name.toLowerCase().includes(text));
  */

  if (ageMatch
    && birthPlaceMatch
    && countryMatch
    && genderMatch
    && groupMatch
    && statusMatch
    && teamMatch
    && typeMatch
    && idolNameMatches(idol, text)) {
    return true;
  }

  return false;
};

export default (idols, {
  text,
  age,
  birthPlace,
  country,
  gender,
  group,
  team,
  status,
  type,
  sortBy
}) => {
  const filters = {
    age,
    birthPlace,
    country,
    gender,
    group,
    status,
    team,
    type
  };

  if (idols.length > 0) {
    const foundIdols = [];
    const selectedFilters = filtersExist(filters);
    const searchedText = text.toLowerCase();

    if (!selectedFilters) {
      return idols.filter((idol) => idolNameMatches(idol, searchedText));
    }

    for (const idol of idols) {
      if (idolMatchesFilters(idol, searchedText, filters)) {
        foundIdols.push(idol);
      }
    }

    // console.log('Found idols', foundIdols, sortBy.value, sortByType(foundIdols, sortBy.value));
    if (sortBy.direction === 'descending') {
      return sortByType(foundIdols, sortBy.value).reverse();
    }
    return sortByType(foundIdols, sortBy.value);
  }
  return idols;
};
