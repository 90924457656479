import { firebase } from '../../firebase';


export const userDataExists = async (userId) => {
  return firebase.database().ref(`users/${userId}`).once('value')
    .then((snapshot) => {
      // console.log('Rows ids', snapshot.val());
      return snapshot.val();
    })
    .catch((error) => {
      // console.log('Failed to check if is new user: ', error);
    });
};

export const saveUserData = async (userId, userData) => {
  return firebase.database().ref(`users/${userId}`).set(userData)
    .then((snapshot) => {
    })
    .catch((error) => {
      // console.log('Failed to save user data: ', error);
    });
};
