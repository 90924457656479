import React from 'react';
import {
  Button, Header, Icon, Modal
} from 'semantic-ui-react';

class RankerDelete extends React.Component {
    state = {
      popupOpen: false
    };

    onClose= () => {
      this.setState({
        popupOpen: false
      });
    }

    onNo = () => {
      this.setState({
        popupOpen: false
      });
    }

    onOpen = () => {
      this.setState({
        popupOpen: true
      });
    }

    onYes = () => {
      const { deleteRanker } = this.props;
      deleteRanker();
      this.setState({
        popupOpen: false
      });
    }

    render() {
      const { popupOpen } = this.state;
      const { loading } = this.props;

      return (
        <Modal
          closeIcon
          size="mini"
          onClose={this.onClose}
          open={popupOpen}
          trigger={(
            <Button
              color="red"
              onClick={this.onOpen}
              loading={loading}
              disabled={loading}
            >Delete
            </Button>
)}
        >
          <Header icon="exclamation" content="Delete Ranker?" />
          <Modal.Actions>
            <Button onClick={this.onNo} color="red">
              <Icon name="remove" /> No
            </Button>
            <Button onClick={this.onYes} color="green">
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
}

export default RankerDelete;
