import React from 'react';

const AvatarName = ({ name }) => {
  return (
    <div className="idol-list-item-avatar-name" style={{ fontSize: name.length >= 17 && '0.7vw' }}>
      {name}
    </div>
  );
};

export default AvatarName;
