/**
 * Status
 * *Ex: Graduated
 * @param {string} status
 * @returns string
 */
const statusDto = (status) => {
  return status || null;
};

export default statusDto;
