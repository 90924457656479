import uuid from 'uuid';
import idolDto from './idol/idolDto';
import '../../models/typings';

const formatFilterValue = (type, values) => {
  const filterValues = [];

  values.forEach(value => {
    if (!value) {
      return;
    }
    filterValues.push({
      key: uuid(),
      text: value,
      value: value.toString().toLowerCase()
    });
  });
  return filterValues;
};

const buildIdolFilterData = (idolDto, currentFilterData) => {
  const filterData = {
    ages: currentFilterData.ages,
    birthPlaces: currentFilterData.birthPlaces,
    countries: currentFilterData.countries,
    genders: currentFilterData.genders,
    groups: currentFilterData.groups,
    teams: currentFilterData.teams,
    types: currentFilterData.types,
    status: currentFilterData.status
  };

  if (!idolDto.positions) {
    return currentFilterData;
  }

  idolDto.positions.forEach(position => {
    if (position.groupName !== 'null') {
      filterData.groups.add(position.groupName);
    }
    if (position.teamName !== 'null') {
      filterData.teams.add(position.teamName);
    }
  });

  if (idolDto.age) {
    filterData.ages.add(idolDto.age);
  }

  if (idolDto.birthPlace) {
    filterData.birthPlaces.add(idolDto.birthPlace);
  }

  if (idolDto.status) {
    filterData.status.add(idolDto.status);
  }

  if (idolDto.country) {
    filterData.countries.add(idolDto.country);
  }

  if (idolDto.gender) {
    filterData.genders.add(idolDto.gender);
  }

  if (idolDto.type) {
    filterData.types.add(idolDto.type);
  }


  return filterData;
};
export const buildIdolsData = (rawIdols) => {
  const idols = [];
  let filterData = {
    ages: new Set(),
    birthPlaces: new Set(),
    countries: new Set(),
    genders: new Set(),
    groups: new Set(),
    teams: new Set(),
    types: new Set(),
    status: new Set()
  };

  for (const id in rawIdols) {
    const rawIdol = rawIdols[id];
    const idol = idolDto(rawIdol);
    filterData = buildIdolFilterData(idol, filterData);

    idols.push(idol);
  }

  const idolsData = {
    idols,
    filters: {
      ages: formatFilterValue('age', Array.from(filterData.ages).sort()),
      birthPlaces: formatFilterValue('birthplace', Array.from(filterData.birthPlaces).sort()),
      countries: formatFilterValue('country', Array.from(filterData.countries).sort()),
      genders: formatFilterValue('gender', Array.from(filterData.genders).sort()),
      groups: formatFilterValue('group', Array.from(filterData.groups).sort()),
      status: formatFilterValue('status', Array.from(filterData.status).sort()),
      teams: formatFilterValue('team', Array.from(filterData.teams).sort()),
      types: formatFilterValue('type', Array.from(['J-pop', 'K-pop']).sort())
    }
  };
  // console.log('Mapper idols', idolsData);
  return idolsData;
};


export default buildIdolsData;
