import { loadAllIdols } from '../../../firebase/index';


export const loadAllIdolsSuccess = (idols, filters) => ({
  type: 'LOAD_ALL_IDOLS_SUCCESS',
  idols,
  filters
});

export const loadAllIdolsFail = (error) => ({
  type: 'LOAD_ALL_IDOLS_FAIL',
  error
});

export const startLoadAllIdols = () => {
  return (dispatch) => {
    dispatch({ type: 'LOAD_ALL_IDOLS' });

    loadAllIdols()
      .then(({ idols, filters }) => dispatch(loadAllIdolsSuccess(idols, filters)))
      .catch((error) => dispatch(loadAllIdolsFail(`Failed to delete ranker: ${error}`)));
  };
};
