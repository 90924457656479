import rankerTestData from 'testData/ranker-test-data';
import { getRankerCopy } from 'helpers';

const rankersReducerDefaultState = {
  creatingRanker: false,
  deletingRanker: false,
  loading: false,
  loadingRows: false,
  rankers: [],
  rankerData: {
    idols: null,
    rows: null,
    rowOrder: null,
    metadata: null
  },
  rows: [],
  savingRanker: false,
  selectedRanker: null,
  // testRows: rankerTestData,
  testRows: null,
  originalRankerData: null,
  options: {
    previewMode: false,
    showRankerName: true,
    showRankerLogo: false,
    showRowNames: true,
    showIdolPosition: true
  },
  error: ''
};

export default (state = rankersReducerDefaultState, action) => {
  switch (action.type) {
    case 'ADD_ROW':
      // console.log('Add Row payload', action.row.id);
      return {
        ...state,
        testRows: {
          ...state.testRows,
          rows: {
            ...state.testRows.rows,
            [action.row.id]: action.row
          },
          rowOrder: [...state.testRows.rowOrder, action.row.id]
        }
      };

    case 'CREATE_RANKER':
      return {
        ...state,
        creatingRanker: true
      };

    case 'CREATE_RANKER_SUCCESS':
      return {
        ...state,
        rankers: [...state.rankers, action.newRanker],
        creatingRanker: false
      };

    case 'CREATE_RANKER_FAIL':
      return {
        ...state,
        creatingRanker: false
      };

    case 'DELETE_RANKER':
      return {
        ...state,
        deletingRanker: true
      };

    case 'DELETE_RANKER_FAIL':
      return {
        ...state,
        deletingRanker: false
      };

    case 'DELETE_RANKER_SUCCESS':
      return {
        ...state,
        deletingRanker: false,
        selectedRanker: null,
        testRows: null
      };

    case 'LOAD_ROWS':
      return {
        ...state,
        loadingRows: true
      };

    case 'LOAD_ROWS_FAIL':
      return {
        ...state,
        testRows: null,
        loadingRows: false,
        error: action.error
      };

    case 'LOAD_ROWS_SUCCESS':
      return {
        ...state,
        loadingRows: false,
        // rows: action.rows
        selectedRanker: action.rowsData.metadata.id,
        testRows: action.rowsData,
        originalRankerData: getRankerCopy(action.rowsData)
      };

    case 'LOAD_USER_RANKERS':
      return {
        ...state,
        loading: true
      };

    case 'LOAD_USER_RANKERS_SUCCESS':
      return {
        ...state,
        rankers: action.rankers,
        loading: false
      };

    case 'LOAD_USER_RANKERS_FAIL':
      return {
        ...state,
        loading: false
      };

    case 'LOGOUT':
      return {
        ...state,
        selectedRanker: null,
        testRows: rankerTestData
      };

    case 'SAVE_RANKER':
      return {
        ...state,
        savingRanker: true
      };

    case 'SAVE_RANKER_FAIL':
      return {
        ...state,
        savingRanker: false
      };

    case 'SAVE_RANKER_SUCCESS':
      return {
        ...state,
        savingRanker: false
      };

    case 'SELECT_RANKER':
      return {
        ...state,
        selectedRanker: action.ranker
      };

    case 'UPDATE_RANKER':
      return {
        ...state,
        testRows: getRankerCopy(action.rankerData)
      };

    case 'UPDATE_RANKER_OPTION':
      return {
        ...state,
        options: {
          ...state.options,
          [action.option]: action.value
        }
      };

    case 'UPDATE_RANKER_OPTIONS':
      return {
        ...state,
        options: action.rankerOptions
      };

    default:
      return {
        ...state
      };
  }
};
