import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import IdolAvatarDraggable from './IdolAvatarDraggable';


const IdolAvatarDroppable = ({ idol, index }) => {
  return (
    <Droppable droppableId="idolList" direction="vertical" type="IdolsekaDroppableType">
      { (provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <IdolAvatarDraggable
            key={idol.id}
            idol={idol}
            index={index}
          />
          {provided.placeholder}
        </div>
      ) }

    </Droppable>
  );
};

export default IdolAvatarDroppable;
