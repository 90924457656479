const rankerTestData = {
  idols: {
    'd4d068fb-efd0-487b-bc84-499a03981bbe': {
      age: 21,
      birthPlace: 'Hiroshima',
      country: 'Japan',
      displayName: {
        leftSideName: '藤本冬香',
        bottomName: 'Fujimoto Fuyuka'
      },
      folderId: 'SKE_9th_Fujimoto_Fuyuka',
      gender: 'Female',
      id: '0219adbb-2175-40f2-ad88-531e29a72450',
      name: 'Fujimoto Fuyuka',
      nameStyled: '藤本冬香',
      nickname: 'Fuyu',
      nicknameStyled: 'ふゆ',
      positions: [
        {
          groupName: 'SKE48',
          groupColor: '#ffc62b',
          teamName: 'Kenkyuusei',
          teamColor: 'null'
        }
      ],
      status: 'Kenkyuusei (研究生)',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Fujimoto_Fuyuka'
    },
    '827f6c46-8ba4-4d74-a621-16cf4cafcf84': {
      age: 23,
      birthPlace: 'Kanagawa',
      country: 'Japan',
      displayName: {
        leftSideName: '阿部マリア',
        bottomName: 'Abe Maria'
      },
      folderId: 'AKB_10th_Abe_Maria',
      gender: 'Female',
      id: 'c3c39130-cff6-40e2-aa7b-138932d54e93',
      name: 'Abe Maria',
      nameStyled: '阿部マリア',
      nickname: 'Abemaru',
      nicknameStyled: 'あべまる',
      positions: [
        {
          groupName: 'AKB48',
          groupColor: '#ef92b0',
          teamName: 'Team TP',
          teamColor: 'null'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Abe_Maria'
    },
    '37113bfa-0280-4900-8bda-8cf7019d8450': {
      age: 19,
      birthPlace: 'Chiba',
      country: 'Japan',
      displayName: {
        leftSideName: '佐藤妃星',
        bottomName: 'Sato Kiara'
      },
      folderId: 'AKB_15th_Sato_Kiara',
      gender: 'Female',
      id: '0b4487db-4cba-4c90-be2e-d1c2c72072e6',
      name: 'Sato Kiara',
      nameStyled: '佐藤妃星',
      nickname: 'Kiichan',
      nicknameStyled: 'きぃちゃん',
      positions: [
        {
          groupName: 'AKB48',
          groupColor: '#ef92b0',
          teamName: 'Team 4',
          teamColor: '#FFD300'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Sato_Kiara'
    },
    '0d1cb528-e668-400c-9231-46ad7c2b6bf9': {
      age: 18,
      birthPlace: 'Tokyo',
      country: 'Japan',
      displayName: {
        leftSideName: '矢吹奈子',
        bottomName: 'Yabuki Nako'
      },
      folderId: 'HKT_3rd_Yabuki_Nako',
      gender: 'Female',
      id: '0aeb2419-ed07-4a52-9b2d-64926eb2eaec',
      name: 'Yabuki Nako',
      nameStyled: '矢吹奈子',
      nickname: 'Nako',
      nicknameStyled: 'なこ',
      positions: [
        {
          groupName: 'HKT48',
          groupColor: '#000000',
          teamName: 'Team H',
          teamColor: '#C3E76B'
        },
        {
          groupName: 'IZ*ONE',
          groupColor: '#c42527',
          teamName: 'null'
        }
      ],
      status: 'Hiatus',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Yabuki_Nako'
    },
    '7f047dcc-4495-42da-ac6c-5758ddc0a604': {
      age: 19,
      birthPlace: 'Nagasaki',
      country: 'Japan',
      displayName: {
        leftSideName: '池田楓',
        bottomName: 'Ikeda Kaede'
      },
      folderId: 'SKE_9th_Ikeda_Kaede',
      gender: 'Female',
      id: '0a836137-2fd6-4630-96ba-756e1c471e8c',
      name: 'Ikeda Kaede',
      nameStyled: '池田楓',
      nickname: 'Kaede',
      nicknameStyled: '楓',
      positions: [
        {
          groupName: 'SKE48',
          groupColor: '#ffc62b',
          teamName: 'Kenkyuusei',
          teamColor: 'null'
        }
      ],
      status: 'Kenkyuusei (研究生)',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Ikeda_Kaede'
    },
    '3e0f7863-ff3d-48d7-8dba-19736cd31e60': {
      age: 20,
      birthPlace: 'Tochigi',
      country: 'Japan',
      displayName: {
        leftSideName: '福士奈央',
        bottomName: 'Fukushi Nao'
      },
      folderId: '1st_Draft_Fukushi_Nao',
      gender: 'Female',
      id: '09477403-fb4b-4545-a0a5-7c2fb8948aa8',
      name: 'Fukushi Nao',
      nameStyled: '福士奈央',
      nickname: 'Donchan',
      nicknameStyled: 'どんちゃん',
      positions: [
        {
          groupName: 'SKE48',
          groupColor: '#ffc62b',
          teamName: 'Team E',
          teamColor: '#74BEA7'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Fukushi_Nao'
    },
    'b5e2b529-8d6f-4e51-9a4f-148a276f58eb': {
      age: 20,
      birthPlace: 'Fukuoka',
      country: 'Japan',
      displayName: {
        leftSideName: '外薗葉月',
        bottomName: 'Hokazono Hazuki'
      },
      folderId: 'HKT_3rd_Hokazono_Hazuki',
      gender: 'Female',
      id: '08a13d25-fa38-4562-aa39-60a73243772d',
      name: 'Hokazono Hazuki',
      nameStyled: '外薗葉月',
      nickname: 'Hazuki',
      nicknameStyled: 'はづき',
      positions: [
        {
          groupName: 'HKT48',
          groupColor: '#000000',
          teamName: 'Team TII',
          teamColor: '#ffdf00'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Hokazono_Hazuki'
    },
    '59032fe1-de34-4fe1-af11-3fe7c2f04324': {
      age: 18,
      birthPlace: 'Shizuoka',
      country: 'Japan',
      displayName: {
        leftSideName: '勝又彩央里',
        bottomName: 'Katsumata Saori'
      },
      folderId: '3rd_Draft_Katsumata_Saori',
      gender: 'Female',
      id: '05281dc7-7c92-487b-baeb-bd3315f30f96',
      name: 'Katsumata Saori',
      nameStyled: '勝又彩央里',
      nickname: 'Saorin',
      nicknameStyled: 'さおりん',
      positions: [
        {
          groupName: 'AKB48',
          groupColor: '#ef92b0',
          teamName: 'Team K',
          teamColor: '#80bb18'
        }
      ],
      status: 'Kenkyuusei (研究生)',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Katsumata_Saori'
    },
    '865f1cfc-6f92-44e1-97b1-704916a1b25e': {
      age: 18,
      birthPlace: 'Fukuoka',
      country: 'Japan',
      displayName: {
        leftSideName: '荒巻美咲',
        bottomName: 'Aramaki Misaki'
      },
      folderId: 'HKT_3rd_Aramaki_Misaki',
      gender: 'Female',
      id: '048746e1-902e-42e3-88e0-a703085e8f89',
      name: 'Aramaki Misaki',
      nameStyled: '荒巻美咲',
      nickname: 'Mirun',
      nicknameStyled: 'みるん',
      positions: [
        {
          groupName: 'HKT48',
          groupColor: '#000000',
          teamName: 'Team TII',
          teamColor: '#ffdf00'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Aramaki_Misaki'
    },
    '15cb85f9-e009-44e8-b54a-5c05b10de2db': {
      age: 18,
      birthPlace: 'Tokyo',
      country: 'Japan',
      displayName: {
        leftSideName: '達家真姫宝',
        bottomName: 'Tatsuya Makiho'
      },
      folderId: 'AKB_15th_Tatsuya_Makiho',
      gender: 'Female',
      id: '03945f68-3194-4ff8-a161-8ef3445e1d62',
      name: 'Tatsuya Makiho',
      nameStyled: '達家真姫宝',
      nickname: 'Makichan',
      nicknameStyled: 'まきちゃん',
      positions: [
        {
          groupName: 'AKB48',
          groupColor: '#ef92b0',
          teamName: 'Team 4',
          teamColor: '#FFD300'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Tatsuya_Makiho'
    },
    '04eaf165-d903-4646-b8e9-bc074ed396e3': {
      age: 18,
      birthPlace: 'Saga',
      country: 'Japan',
      displayName: {
        leftSideName: '宮崎想乃',
        bottomName: 'Miyazaki Sono'
      },
      folderId: 'HKT_4th_Miyazaki_Sono',
      gender: 'Female',
      id: '03a67781-9bea-4b5a-80aa-bef63efbba58',
      name: 'Miyazaki Sono',
      nameStyled: '宮崎想乃',
      nickname: 'Sonochin',
      nicknameStyled: 'そのちん',
      positions: [
        {
          groupName: 'HKT48',
          groupColor: '#000000',
          teamName: 'Team TII',
          teamColor: '#ffdf00'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Miyazaki_Sono'
    },
    '41be4ca7-9c37-4ab3-82ab-84aa7d2f587a': {
      age: 20,
      birthPlace: 'Saitama',
      country: 'Japan',
      displayName: {
        leftSideName: '荻野由佳',
        bottomName: 'Ogino Yuka'
      },
      folderId: '2nd_Draft_Ogino_Yuka',
      gender: 'Female',
      id: '035a53a7-7cc4-4282-a67c-f39f38e9828c',
      name: 'Ogino Yuka',
      nameStyled: '荻野由佳',
      nickname: 'Ogiyuka',
      nicknameStyled: 'おぎゆか',
      positions: [
        {
          groupName: 'NGT48',
          groupColor: '#e60012',
          teamName: 'Team 1stGen',
          teamColor: 'null'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Ogino_Yuka'
    },
    'bd43a0cc-390c-4385-ad7f-4f448ca4c137': {
      age: 20,
      birthPlace: 'Saitama',
      country: 'Japan',
      displayName: {
        leftSideName: '荻野由佳',
        bottomName: 'Ogino Yuka'
      },
      folderId: '2nd_Draft_Ogino_Yuka',
      gender: 'Female',
      id: '035a53a7-7cc4-4282-a67c-f39f38e9828c',
      name: 'Ogino Yuka',
      nameStyled: '荻野由佳',
      nickname: 'Ogiyuka',
      nicknameStyled: 'おぎゆか',
      positions: [
        {
          groupName: 'NGT48',
          groupColor: '#e60012',
          teamName: 'Team 1stGen',
          teamColor: 'null'
        }
      ],
      status: 'Member',
      type: 'J-pop',
      wiki: 'http://stage48.net/wiki/index.php/Ogino_Yuka'
    },
    'de32d751-b537-47c5-b471-c6a60a29db78': {
      age: 20,
      birthPlace: 'Seoul',
      country: 'South Korea',
      displayName: {
        leftSideName: '채영',
        bottomName: 'Chaeyoung'
      },
      folderId: 'TWICE_Chaeyoung',
      gender: 'Female',
      id: '51908a05-c42c-40bb-8144-834c627e1d33',
      name: 'Son Chaeyoung',
      nameStyled: '손채영',
      nickname: 'Chaeyoung',
      nicknameStyled: '채영',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    '5055c170-cb64-4137-9083-e09dacc7cd14': {
      age: 21,
      birthPlace: 'Seongnam',
      country: 'South Korea',
      displayName: {
        leftSideName: '다현',
        bottomName: 'Dahyun'
      },
      folderId: 'TWICE_Dahyun',
      gender: 'Female',
      id: 'cffd2afe-6870-4c99-88ee-a9e41b5332a0',
      name: 'Kim Dahyun',
      nameStyled: '김다현',
      nickname: 'Dahyun',
      nicknameStyled: '다현',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'addd0d28-bca4-4e82-a9c1-1f69f3f70b4d': {
      age: 22,
      birthPlace: 'Suwon',
      country: 'South Korea',
      displayName: {
        leftSideName: '정연',
        bottomName: 'Jeongyeon'
      },
      folderId: 'TWICE_Jeongyeon',
      gender: 'Female',
      id: '5de5334c-6bac-42d0-a3ca-68d951f14b0a',
      name: 'Yoo Jeongyeon',
      nameStyled: '유정연',
      nickname: 'Jeongyeon',
      nicknameStyled: '정연',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'edac4668-744e-43ae-989f-11d0d5771b83': {
      age: 22,
      birthPlace: 'Guri',
      country: 'South Korea',
      displayName: {
        leftSideName: '지효',
        bottomName: 'Jihyo'
      },
      folderId: 'TWICE_Jihyo',
      gender: 'Female',
      id: 'f44d03db-2af1-42d6-9155-18192807c2cf',
      name: 'Park Jisoo',
      nameStyled: '박지수',
      nickname: 'Jihyo',
      nicknameStyled: '지효',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    '9df4dc6b-d774-4ce3-9e6c-4fdad4da0608': {
      age: 22,
      birthPlace: 'San Antonio',
      country: 'Japan',
      displayName: {
        leftSideName: '미나',
        bottomName: 'Mina'
      },
      folderId: 'TWICE_Mina',
      gender: 'Female',
      id: '55f279c4-a03a-4f8f-886a-d8e73b88d0f9',
      name: 'Myoui Mina',
      nameStyled: '묘이미나',
      nickname: 'Mina',
      nicknameStyled: '미나',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    '10e4adf3-3cfc-4acc-8e85-6eb863a24edc': {
      age: 22,
      birthPlace: 'Kyoto',
      country: 'Japan',
      displayName: {
        leftSideName: '모모',
        bottomName: 'Momo'
      },
      folderId: 'TWICE_Momo',
      gender: 'Female',
      id: '9bb6e570-acd9-4cb4-841c-ae526474be6a',
      name: 'Hirai Momo',
      nameStyled: '히라이모모',
      nickname: 'Momo',
      nicknameStyled: '모모',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    '23068161-15ae-4665-bff9-e78314d627a2': {
      age: 24,
      birthPlace: 'Seoul',
      country: 'South Korea',
      displayName: {
        leftSideName: '나연',
        bottomName: 'Nayeon'
      },
      folderId: 'TWICE_Nayeon',
      gender: 'Female',
      id: 'f5b2b294-4119-4d0d-aad8-96e232cfe0db',
      name: 'Im Nayeon',
      nameStyled: '임나연',
      nickname: 'Nayeon',
      nicknameStyled: '나연',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    '2ee14d5b-f452-4cf2-b122-24f32cf7c911': {
      age: 22,
      birthPlace: 'Osaka',
      country: 'Japan',
      displayName: {
        leftSideName: '사나',
        bottomName: 'Sana'
      },
      folderId: 'TWICE_Sana',
      gender: 'Female',
      id: '29f82544-6d30-46ec-a21f-25b2ab51a009',
      name: 'Minatozaki Sana',
      nameStyled: '미나토자키사나',
      nickname: 'Sana',
      nicknameStyled: '사나',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'cfee88c2-8598-4781-b2c8-16555b789c38': {
      age: 20,
      birthPlace: 'Tainan',
      country: 'Taiwan',
      displayName: {
        leftSideName: '쯔위',
        bottomName: 'Tzuyu'
      },
      folderId: 'TWICE_Tzuyu',
      gender: 'Female',
      id: 'c8812f10-af8c-41fb-8881-359094036e6f',
      name: 'Chou Tzuyu',
      nameStyled: '저우쯔위',
      nickname: 'Tzuyu',
      nicknameStyled: '쯔위',
      positions: [
        {
          groupName: 'Twice',
          groupColor: '#eb8038|#ff00ff',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    '4bac0451-8ba2-4228-a838-fcbc355dc1ae': {
      age: 25,
      birthPlace: 'Gwangju',
      country: 'South Korea',
      displayName: {
        leftSideName: '제이홉',
        bottomName: 'J-Hope'
      },
      folderId: 'BTS_J-Hope',
      gender: 'Male',
      id: 'dda62acd-7916-4b60-a1f0-3f4b612c8998',
      name: 'Jung Hoseok',
      nameStyled: '정호석',
      nickname: 'J-Hope',
      nicknameStyled: '제이홉',
      positions: [
        {
          groupName: 'BTS',
          groupColor: '#C0C0C0',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'd7560ba2-b89d-4b97-8e93-a0653545c5a3': {
      age: 24,
      birthPlace: 'Busan',
      country: 'South Korea',
      displayName: {
        leftSideName: '지민',
        bottomName: 'Jimin'
      },
      folderId: 'BTS_Jimin',
      gender: 'Male',
      id: '637768c3-55cd-403b-a49f-11f79f1c3454',
      name: 'Park Jimin',
      nameStyled: '박지민',
      nickname: 'Jimin',
      nicknameStyled: '지민',
      positions: [
        {
          groupName: 'BTS',
          groupColor: '#C0C0C0',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'eea90e82-2bdc-4b47-84b4-baf48324808f': {
      age: 26,
      birthPlace: 'Gwacheon',
      country: 'South Korea',
      displayName: {
        leftSideName: '진',
        bottomName: 'Jin'
      },
      folderId: 'BTS_Jin',
      gender: 'Male',
      id: '0b616895-6ad1-408c-b6d6-b9f6f6eeadc3',
      name: 'Kim Seokjin',
      nameStyled: '김석진',
      nickname: 'Jin',
      nicknameStyled: '진',
      positions: [
        {
          groupName: 'BTS',
          groupColor: '#C0C0C0',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'bfc430bf-3a89-4f82-b752-e759444b391a': {
      age: 22,
      birthPlace: 'Busan',
      country: 'South Korea',
      displayName: {
        leftSideName: '정국',
        bottomName: 'Jungkook'
      },
      folderId: 'BTS_Jungkook',
      gender: 'Male',
      id: 'aa8c14bd-c105-4080-af92-4c3bcc7290ad',
      name: 'Jeon Jungkook',
      nameStyled: '전정국',
      nickname: 'Jungkook',
      nicknameStyled: '정국',
      positions: [
        {
          groupName: 'BTS',
          groupColor: '#C0C0C0',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'fd0ce50e-f2c2-4324-b9e6-991dcc9bbe0d': {
      age: 25,
      birthPlace: 'Ilsan',
      country: 'South Korea',
      displayName: {
        leftSideName: '알엠',
        bottomName: 'RM'
      },
      folderId: 'BTS_RM',
      gender: 'Male',
      id: '07462abb-4803-4cf8-a442-69245dbfe6ad',
      name: 'Kim Namjoon',
      nameStyled: '김남준',
      nickname: 'RM',
      nicknameStyled: '알엠',
      positions: [
        {
          groupName: 'BTS',
          groupColor: '#C0C0C0',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'c51c1773-51bb-4478-a587-fb257f550f09': {
      age: 26,
      birthPlace: 'Daegu',
      country: 'South Korea',
      displayName: {
        leftSideName: '슈가',
        bottomName: 'Suga'
      },
      folderId: 'BTS_Suga',
      gender: 'Male',
      id: '0c29f7fb-0edd-4056-b900-0ba5b5a06939',
      name: 'Min Yoongi',
      nameStyled: '민윤기',
      nickname: 'Suga',
      nicknameStyled: '슈가',
      positions: [
        {
          groupName: 'BTS',
          groupColor: '#C0C0C0',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    },
    'd95fc029-c861-4c9a-ba66-71730e905839': {
      age: 23,
      birthPlace: 'Daegu',
      country: 'South Korea',
      displayName: {
        leftSideName: '뷔',
        bottomName: 'V'
      },
      folderId: 'BTS_V',
      gender: 'Male',
      id: 'a95bd7e0-466e-4c6c-9b8a-ea60580ccc63',
      name: 'Kim Taehyung',
      nameStyled: '김태형',
      nickname: 'V',
      nicknameStyled: '뷔',
      positions: [
        {
          groupName: 'BTS',
          groupColor: '#C0C0C0',
          teamName: 'null',
          teamColor: 'null'
        }
      ],
      status: null,
      type: 'K-pop',
      wiki: null
    }
  },
  rows: {
    '69e7ae8e-e3a6-4dae-a260-d751afb61c49': {
      created_on: '2019-08-04T20:08:04+01:00',
      id: '69e7ae8e-e3a6-4dae-a260-d751afb61c49',
      name: 'TWICE',
      updated_on: '2019-09-10T00:26:06+01:00',
      user_id: 'JgbMmKgAG3bcRMYOGvFh6FcoSih1',
      idolIds: [
        'edac4668-744e-43ae-989f-11d0d5771b83',
        '2ee14d5b-f452-4cf2-b122-24f32cf7c911',
        'cfee88c2-8598-4781-b2c8-16555b789c38',
        '23068161-15ae-4665-bff9-e78314d627a2',
        '10e4adf3-3cfc-4acc-8e85-6eb863a24edc',
        '9df4dc6b-d774-4ce3-9e6c-4fdad4da0608',
        'addd0d28-bca4-4e82-a9c1-1f69f3f70b4d',
        '5055c170-cb64-4137-9083-e09dacc7cd14',
        'de32d751-b537-47c5-b471-c6a60a29db78'
      ]
    },
    'c0b70488-fd02-4060-b856-8dcc12c89be8': {
      id: 'c0b70488-fd02-4060-b856-8dcc12c89be8',
      name: 'BTS',
      created_on: '2019-10-28T23:01:31+00:00',
      updated_on: '2019-10-28T23:01:31+00:00',
      user_id: 'JgbMmKgAG3bcRMYOGvFh6FcoSih1',
      idolIds: [
        'd95fc029-c861-4c9a-ba66-71730e905839',
        'c51c1773-51bb-4478-a587-fb257f550f09',
        'fd0ce50e-f2c2-4324-b9e6-991dcc9bbe0d',
        'bfc430bf-3a89-4f82-b752-e759444b391a',
        'eea90e82-2bdc-4b47-84b4-baf48324808f',
        'd7560ba2-b89d-4b97-8e93-a0653545c5a3',
        '4bac0451-8ba2-4228-a838-fcbc355dc1ae'
      ]
    }
  },
  rowOrder: [
    '69e7ae8e-e3a6-4dae-a260-d751afb61c49',
    'c0b70488-fd02-4060-b856-8dcc12c89be8'
  ],
  metadata: {
    created_on: '2019-08-04T20:07:36+01:00',
    id: 'b174ecdd-6960-4433-a197-6368b481beed',
    name: 'KPOP STARS',
    updated_on: '2019-08-04T20:07:36+01:00',
    user_id: 'JgbMmKgAG3bcRMYOGvFh6FcoSih1'
  }
};

export default rankerTestData;
