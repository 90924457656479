/**
 * Folder id
 * * Ex: Teen Top_Changjo
 * @param {string} folderId
 * @returns string
 */
const folderDto = (folderId) => {
  if (!folderId) {
    return 'default';
  }
  return folderId;
};

export default folderDto;
