import React from 'react';

const AvatarImage = ({
  displayName, folderId, gender, groupPositionIndex, positions, profilesFolder, type,
  getBackgroundColor, getPortraitBackgroundUrl
}) => {
  return (

  // There is 2 level of divs with 2 backgrounds to go around an issue when generating screenshots
  // On a previous implementation, the background url and gradient/color were declared
  // on the same div, on the background string.
  // Html2Canvas was not able to handle different types on the same string
  // so its now broken into 2 divs.
  // The top div has the background color/gradient and
  // the bottom one will have an image url that will cover the gradient/color
  // of the parent if the image exists. Previous background string:
  /*
  background: `url(${getPortraitBackgroundUrl(positions, type)})  no-repeat center center,
  ${getBackgroundColor(gender, positions, 'avatar', groupPositionIndex)} no-repeat center center`,
  */

    <div style={{
      background: `${getBackgroundColor(gender, positions, 'avatar', groupPositionIndex)} no-repeat center center`,
      backgroundSize: 'cover'
    }}
    >

      <div
        style={{
          background: `url(${getPortraitBackgroundUrl(positions, type)})  no-repeat center center`,
          backgroundSize: 'cover'
        }}
        className="idol-list-item-avatar-image"
      >
        <img
          src={encodeURI(`${profilesFolder}/${folderId}/profile.jpg`)}
          alt={displayName}
          style={{
            height: '14vh',
            width: '6vw',
            padding: '5% 6% 1% 6%',
            minWidth: '80px',
            objectFit: 'cover',
            backgroundImage: `url(${getPortraitBackgroundUrl(positions, type)})  no-repeat center center`
          }}
        />
      </div>
    </div>

  );
};

export default AvatarImage;
