import moment from 'moment';
/**
 * Takes a date string like "1995-11-16" and calculates
 * the age
 * @param {string} birthDate
 * @returns {number}
 */
const ageDto = (birthDate) => {
  if (!birthDate) {
    return 0;
  }
  return moment().diff(birthDate, 'years');
};

export default ageDto;
