/**
 * Type
 * *Ex: J-pop or K-pop
 * @param {string} type
 * @returns string
 */
const typeDto = (type) => {
  return type || null;
};

export default typeDto;
