// import { buildIdolsData } from 'mappers';
// import idolTestData from '../../../../public/assets/Idols/Data/Idols_data.json';

// const testData = buildIdolsData(idolTestData.idols);
// const testIdols = testData.idols;
// const testFilters = testData.filters;
// console.log('Test Reducer Data', idolTestData.idols);

const idolReducerDefaultState = {
  // allIdols: testIdols,
  // filters: testFilters,
  allIdols: [],
  filters: null,
  loading: false,
  error: ''
};

export default (state = idolReducerDefaultState, action) => {
  switch (action.type) {
    case 'LOAD_ALL_IDOLS':
      return {
        ...state,
        loading: true
      };

    case 'LOAD_ALL_IDOLS_SUCCESS':
      return {
        ...state,
        allIdols: action.idols,
        filters: action.filters,
        loading: false
      };

    case 'LOAD_ALL_IDOLS_FAIL':
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return {
        ...state
      };
  }
};
