import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// import idolData from '../../public/assets/Idols/Data/idols_data_190913.json';

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID
};

firebase.initializeApp(config);

const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
// console.log('Local Data', idolData);


// idolData.idols.forEach(idol => {
//   console.log(idol);
//   database.ref(`idols/${idol.ID}`).set(idol);
// });


export { firebase, googleAuthProvider, database as default };
