import { firebase, googleAuthProvider } from '../../../firebase/firebase';
import { userDataExists, saveUserData } from '../../../firebase/index';

export const login = (uid) => ({
  type: 'LOGIN',
  uid
});

export const updateUserData = (user) => ({
  type: 'UPDATE_USER_DATA',
  user
});

export const startLoadUserData = (user) => {
  const {
    uid = null,
    displayName = null,
    photoURL = null,
    email = null
  } = user;

  return (dispatch) => {
    userDataExists(user.uid)
      .then((userData) => {
        if (!userData) {
          const newUserData = {
            uid,
            displayName,
            photoURL,
            email,
            isAdmin: false,
            isBasic: true,
            isMod: false,
            isDonor: false,
            isVip: false
          };

          saveUserData(user.uid, newUserData).then((result) => {
            dispatch(updateUserData(newUserData));
          })
            .catch((error) => console.log(`Failed to save  new user: ${error}`));
          return;
        }
        dispatch(updateUserData(userData));
      })
      .catch((error) => console.log(`Failed to check if is new user: ${error}`));
  };
};

export const startLogin = () => {
  return () => {
    return firebase.auth().signInWithPopup(googleAuthProvider);
  };
};

export const logout = () => ({
  type: 'LOGOUT'
});

export const startLogout = () => {
  return () => {
    return firebase.auth().signOut();
  };
};
