/**
 * Name in Romaji
 * * Ex: Choi Jonghyun
 * @param {string} name in Romaji
 * @returns string
 */
const nameDto = (name) => {
  return name ? name.trim() : 'No Data';
};

export default nameDto;
