import { firebase } from '../../firebase';

export const loadRowMetadata = async (rowId) => {
  return firebase.database().ref(`rows/${rowId}`).once('value')
    .then((snapshot) => {
      // console.log('Rows ids', snapshot.val());
      return snapshot.val();
    })
    .catch((error) => {
      // console.log('Failed to load row metadata: ', error);
    });
};

export const updateRowMetadata = async (rowMetadata) => {
  return firebase.database().ref(`rows/${rowMetadata.id}`).set(rowMetadata)
    .then((snapshot) => {
      // console.log('Updated row:', rowMetadata.id, rowMetadata);
      // const updatedRowId = snapshot.key;
      // return updatedRowId;
    })
    .catch((error) => {
      // console.log('Failed to update row metadata: ', error);
    });
};

export const isNewRow = async (rowId) => {
  return firebase.database().ref(`rows/${rowId}`).once('value')
    .then((snapshot) => {
      // console.log('Rows ids', snapshot.val());
      return snapshot.val();
    })
    .catch((error) => {
      // console.log('Failed to check if is new row: ', error);
    });
};
