import { firebase } from '../../firebase';

export const updateRowIdols = async (rowId, idolIds, rankerIdols) => {
  const idols = [];

  for (const id of idolIds) {
    const realIdolId = rankerIdols[id].id;
    idols.push(realIdolId);
  }

  return firebase.database().ref(`row-idols/${rowId}`).set(idols)
    .then((snapshot) => {
      // console.log('Update row idols');
      // const updatedRowId = snapshot.key;
      // return updatedRowId;
    })
    .catch((error) => {
      // console.log('Failed to update row idols: ', error);
    });
};

export default updateRowIdols;
