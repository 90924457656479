/**
 * Nickname in Romaji
 * * Ex: Changjo
 * @param {string} nickname in Romaji
 * @returns string
 */
const nicknameDto = (nickname) => {
  return nickname ? nickname.trim() : 'No Data';
};

export default nicknameDto;
