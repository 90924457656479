import React from 'react';


const calculateFontSize = (length) => {
  if (length >= 9) {
    return '0.7vw';
  }
  if (length >= 7) {
    return '0.8vw';
  }
  if (length >= 5) {
    return '0.9vw';
  }

  return '1.1vw';
};

const AvatarNameStyled = ({ nameStyled }) => {
  return (
    <div className="flex-column-container-wrap-reverse styled-name-label">
      {
        nameStyled.split('').map((letter, index) => (
          <div
            key={`${index + letter}`}
            className="styled-name-label-character"
            style={{ fontSize: calculateFontSize(nameStyled.length) }}
          >{letter}
          </div>
        ))
      }
      {/* {nameStyled} */}
    </div>
  );
};

export default AvatarNameStyled;
