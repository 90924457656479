import React from 'react';
import {
  Header, Icon, Image, Modal
} from 'semantic-ui-react';
import Slider from 'react-slick';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Help = () => (
  <Modal trigger={<button type="button" className="button button--link"> <Icon fitted name="question circle" />    Help</button>
}
  >
    <div>
      <Slider {...settings}>
        <div>
          <div style={{ padding: '1%', marginBottom: '4%' }}>
            <Header
              as="h2"
              content="Drag and drop Idols from the list into a Row!"
              subheader="Double click to remove them."
            />
          </div>
          <Image src="/images//tutorial/tutorial_visuals.PNG" fluid />
        </div>
        <div>

          <div style={{ padding: '1%', marginBottom: '5%' }}>
            <Header
              as="h2"
              content="Generate an Image or share a link to your ranker!"
              subheader="The image link might become unavailable so we recommend that you save the image."
            />
          </div>
          <Image src="/images//tutorial/tutorial_share.PNG" fluid bordered />
        </div>
        <div>
          <div style={{ padding: '1%', marginBottom: '1%' }}>
            <Header
              as="h2"
              content="Use the Ranker Options on the Sidebar!"
            />
            <Header
              as="h4"
              content="Row Names"
              subheader="Toggle to show/hide the row names"
            />
            <Header
              as="h4"
              content="Make all Idols visible"
              subheader="When there are too many idols in the same Row, toggle to show all the idols without scrolling"
            />
          </div>
          <Image src="/images//tutorial/tutorial_ranker_options.PNG" fluid />
        </div>
      </Slider>
    </div>
  </Modal>
);

export default Help;
