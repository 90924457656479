/**
 * Country data
 * * Ex: Japan
 * @param {string} country
 * @returns string
 */
const countryDto = (country) => {
  return country || null;
};

export default countryDto;
