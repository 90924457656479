import moment from 'moment';

export const sortByDate = (items) => {
  return items.sort((a, b) => moment(a.created_on).diff(moment(b.created_on)));
};

export const sortByType = (items, type) => {
  switch (type) {
    case 'date':
      return items.sort((a, b) => moment(a.created_on).diff(moment(b.created_on)));

    case 'name':
      return items.sort((a, b) => a.displayName.bottomName.localeCompare(b.displayName.bottomName));

    case 'age':
      return items.sort((a, b) => a.age - b.age);

    default:
      return items.sort();
  }
};

export default sortByDate;
