import React from 'react';

class RankerCard extends React.Component {
    onButtonClick = () => {
      const { onSelectRanker, ranker } = this.props;
      onSelectRanker(ranker);
    }

    render() {
      const { selectedRanker, ranker } = this.props;
      const isEnabled = selectedRanker && selectedRanker === ranker.id;
      if (selectedRanker) {
        // console.log('RAnker card enabled: ', selectedRanker, ranker.id, isEnabled);
      }

      return (
        isEnabled
          ? (
            <button
              type="button"
              className="button sidebar-ranker-button sidebar-ranker-button-enabled isk-medium-font"
              style={{ fontSize: '0.9vw' }}
              onClick={this.onButtonClick}
            >{ranker.name}
            </button>
          )
          : (
            <button
              type="button"
              className="button sidebar-ranker-button sidebar-ranker-button-disabled isk-medium-font"
              style={{ fontSize: '0.9vw' }}
              onClick={this.onButtonClick}
            >{ranker.name}
            </button>
          )

      );
    }
}


export default RankerCard;
