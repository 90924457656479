import React from 'react';

const AvatarPosition = ({ positionInRow }) => {
  return (
    <h4 style={{ textAlign: 'center' }} className="isk-small-font">
      {`#${positionInRow}`}
    </h4>
  );
};

export default AvatarPosition;
