import { firebase } from '../../firebase';

export const loadRowIdolIds = async (rowId) => {
  return firebase.database().ref(`row-idols/${rowId}`).once('value')
    .then((snapshot) => {
      // console.log('Loaded Row Idol ids:', snapshot.val());
      return snapshot.val() || [];
    })
    .catch((error) => {
      // console.log('Failed to load Row Idol ids: ', error);
    });
};

export default loadRowIdolIds;
