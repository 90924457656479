/**
 * Birthplace
 * *Ex: Seoul
 * @param {string} birthPlace
 * @returns string
 */
const birthPlaceDto = (birthPlace) => {
  return birthPlace || null;
};

export default birthPlaceDto;
