import {
  ageDto, birthPlaceDto, countryDto, displayNameDto, folderDto, genderDto, idDto, nameDto,
  nameStyledDto, nicknameDto, nicknameStyledDto, positionsDto, statusDto, typeDto, wikiDto
} from './index';

const IdolDto = ({
  Birthdate: birthdate,
  Birthplace: birthPlace,
  Country: country,
  NameDisplayType: nameDisplayType,
  Folder_ID: folderId,
  Gender: gender,
  ID: id,
  Name_Romaji: name,
  Name_Kanji: nameStyled,
  Nick_Romaji: nickname,
  Nick_Kana: nicknameStyled,
  Position: positions,
  Status: status,
  Type: type,
  Wiki: wiki
}) => {
  return {
    age: ageDto(birthdate),
    birthPlace: birthPlaceDto(birthPlace),
    country: countryDto(country),
    displayName: displayNameDto(
      nameDisplayType,
      name,
      nameStyled,
      nickname,
      nicknameStyled
    ),
    folderId: folderDto(folderId),
    gender: genderDto(gender),
    id: idDto(id),
    name: nameDto(name),
    nameStyled: nameStyledDto(nameStyled),
    nickname: nicknameDto(nickname),
    nicknameStyled: nicknameStyledDto(nicknameStyled),
    positions: positionsDto(positions),
    status: statusDto(status),
    type: typeDto(type),
    wiki: wikiDto(wiki)
  };
};

export default IdolDto;
