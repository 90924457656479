const getRankerCopy = (ranker) => {
  const rankerCopy = {};
  for (const id in ranker.idols) {
    rankerCopy.idols = {
      ...rankerCopy.idols,
      [id]: ranker.idols[id]
    };
  }
  for (const id in ranker.rows) {
    rankerCopy.rows = {
      ...rankerCopy.rows,
      [id]: ranker.rows[id]
    };
  }
  rankerCopy.rowOrder = [...ranker.rowOrder];
  rankerCopy.metadata = { ...ranker.metadata };

  return rankerCopy;
};

export default getRankerCopy;
