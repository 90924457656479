import React from 'react';
import uuid from 'uuid';

import { List, Visibility } from 'semantic-ui-react';

import IdolCard from '../IdolCard/IdolCard';
import IdolCardPlaceholder from '../IdolCard/IdolCardPlaceholder';

const IdolListContent = ({ handleUpdate, loadingIdols, visibleIdols }) => {
  return (
    <div className="idol-list">
      {/* {console.log('Loading Idols', loadingIdols)} */}
      {
    !loadingIdols
      ? (
        <List divided verticalAlign="middle">
          <Visibility
            onUpdate={handleUpdate}
          >

            {
            visibleIdols.length > 0
              && (
                visibleIdols.map((idol, index) => {
                  return (
                    <List.Item key={uuid()}>
                      <IdolCard idolID={idol.id} idolData={idol} idolIndex={index} />
                    </List.Item>
                  );
                })
              )
          }
          </Visibility>
        </List>
      )
      : <IdolCardPlaceholder />
  }
    </div>
  );
};

export default IdolListContent;
