import React from 'react';
import {
  Button, Input
} from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';


class IdolsekaiClipboard extends React.Component {
    state = {
      value: '',
      copied: false
    }

    componentDidMount() {
      const { defaultValue } = this.props;

      this.setState({
        value: defaultValue,
        copied: false
      });
    }


    render() {
      const { value, copied } = this.state;
      return (
        <div>
          <Input
            rounded="false"
            value={value}
            onChange={({ target: { value } }) => this.setState({ value, copied: false })}
          />
          <CopyToClipboard
            text={value}
            onCopy={() => this.setState({ copied: true })}
          >

            <Button.Group>
              {copied
                ? <Button disabled icon="checkmark" size="large" color="green" />
                : <Button icon="copy" basic size="large" />}
            </Button.Group>

          </CopyToClipboard>

          {/* {copied ? <span style={{ color: 'coral' }}>Copied!</span> : null} */}
        </div>
      );
    }
}

export default IdolsekaiClipboard;
