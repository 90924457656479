import { firebase } from '../../firebase';

export const loadRankerMetadata = async (rankerId) => {
  return firebase.database().ref(`rankers/${rankerId}`).once('value')
    .then((snapshot) => {
      // console.log('Ranker metadata', snapshot.val());
      return snapshot.val();
    })
    .catch((error) => {
      // console.log('Failed to load ranker metadata: ', error);
    });
};

export const updateRankerMetadata = async (rankerMetadata) => {
  return firebase.database().ref(`rankers/${rankerMetadata.id}`).set(rankerMetadata)
    .then((snapshot) => {
      // console.log('Updated row:', rowMetadata.id, rowMetadata);
      // const updatedRowId = snapshot.key;
      // return updatedRowId;
    })
    .catch((error) => {
      // console.log('Failed to update ranker metadata: ', error);
    });
};

export default loadRankerMetadata;
