import React from 'react';
import { connect } from 'react-redux';
import hexToRgba from 'hex-to-rgba';
import { removeSpecialCharacters } from 'helpers';

import JapaneseAvatar from './JapaneseAvatar';
import KoreanAvatar from './KoreanAvatar';

class Idolavatar extends React.Component {
  state = { }

  getBackgroundColor = (gender, positions, type, positionIndex) => {
    const defaultColor = this.getDefaultColor(gender);
    // console.log('positionIndex: ', positionIndex);

    const mainGroupColor = positions[positionIndex].groupColor;

    if (mainGroupColor === 'null') {
      // return type === 'groupLabel' ? defaultColor : '#f0f8fffa';
      return defaultColor;
    }
    return type === 'groupLabel'
      ? this.getBackgroundGradient(mainGroupColor, 'linear-gradient', 'to right')
      : this.getBackgroundGradient(mainGroupColor, 'linear-gradient', 'to right');
  };

  getBackgroundGradient = (mainGroupColor, type, direction) => {
    const mainGroupColors = mainGroupColor.split('|');
    if (mainGroupColors.length > 1) {
      let colors = '';

      for (let index = 0; index < mainGroupColors.length; index += 1) {
        const color = mainGroupColors[index];
        if (index === mainGroupColors.length - 1) {
          colors += color;
        } else {
          colors += `${color},`;
        }
      }

      return `${type}(${direction}, ${colors})`;
    }
    // console.log('Group color: ', mainGroupColor.split('|'), mainGroupColor.split('|').length);
    return hexToRgba(mainGroupColor.split('|')[0]);
  };

  getDefaultColor = (gender) => {
    return gender === 'Male' ? 'rgb(159, 188, 251)' : 'rgb(255, 218, 235)';
  };

  getUniqueGroups = (positions) => {
    const groups = new Set();
    positions.forEach(position => {
      groups.add(position.groupName);
    });
    return Array.from(groups);
  };

  getPortraitBackgroundUrl = (groups, idolType) => {
    const portraitBordersUrl = 'https://idolsekai.s3.eu-central-1.amazonaws.com/Assets/PortraitBackgrounds';
    let fileUrl = '';
    if (groups instanceof Array) {
      const uniqueGroups = this.getUniqueGroups(groups);

      if (idolType === 'J-pop') {
        uniqueGroups.forEach(group => { fileUrl = `${fileUrl + removeSpecialCharacters(group)}-`; });
        fileUrl = fileUrl.slice('-', -1);
      } else {
        fileUrl = `${fileUrl + removeSpecialCharacters(uniqueGroups[0])}`;
      }
    } else {
      fileUrl = `${removeSpecialCharacters(groups)}`;
    }

    const url = `${portraitBordersUrl}/${fileUrl.toLowerCase().trim()}.png`;

    return url;
  };

  render() {
    const { idolData, positionInRow, rankerOptions } = this.props;

    return (
      <div>
        { idolData.type === 'J-pop'
          ? (
            <JapaneseAvatar
              getBackgroundColor={this.getBackgroundColor}
              getPortraitBackgroundUrl={this.getPortraitBackgroundUrl}
              getUniqueGroups={this.getUniqueGroups}
              idolData={idolData}
              positionInRow={positionInRow}
              rankerOptions={rankerOptions}
            />
          )
          : (
            <KoreanAvatar
              getBackgroundColor={this.getBackgroundColor}
              getPortraitBackgroundUrl={this.getPortraitBackgroundUrl}
              idolData={idolData}
              positionInRow={positionInRow}
              rankerOptions={rankerOptions}
            />
          )
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log('[Idol Avatar] State: ', state);
  return {
    rankerOptions: state.rankers.options
  };
};


export default connect(mapStateToProps, null)(Idolavatar);
