import { buildRankerData } from 'mappers';

import { loadRankerMetadata, updateRankerMetadata } from './ranker-metadata';
import { loadRankerIdols } from './ranker-idols';
import { loadRankerRowIds } from './row-ids';
import { deleteRankerRows, updateRankerRows } from './ranker-rows';
import { loadRowsMetadata, updateRowsMetadata } from '../row/rows-metadata';
import { deleteRow } from '../row/delete-row';
import { createRanker } from './creater-ranker';
import { deleteRanker } from './delete-ranker';
import { createUserRanker, deleteUserRanker, loadUserRankerIds } from '../user/user-ranker';

export const createRankerData = async (userId, rankerId, rankerData, rankerLimit) => {
  // const rankerLimit = 5;
  // console.log('Create ranker', rankerId);
  const userRankers = await loadUserRankerIds(userId);

  // The limit verification is also done here to prevent creation on another open browser
  // that hasnt reached the limit yet
  if (userRankers.length < rankerLimit) {
    // console.log('User rankers: ', userRankers.length);
    await createRanker(rankerId, rankerData);
    await createUserRanker(userId, rankerId);
    // console.log('Created ranker', rankerId);
  }
};

export const deleteRankerData = async (ranker, userId) => {
  // console.log('Delete ranker', ranker);

  for (const id of ranker.rowOrder) {
    await deleteRow(id);
  }

  await deleteRankerRows(ranker);
  await deleteUserRanker(ranker, userId);
  await deleteRanker(ranker);

  // console.log('Deleted ranker', ranker);
};

export const loadRankerData = async (rankerId) => {
  try {
    const rankerMetadata = await loadRankerMetadata(rankerId);
    if (rankerMetadata) {
      const rowIds = await loadRankerRowIds(rankerId);
      const rowsMetadata = await loadRowsMetadata(rowIds);
      const idolsMetadata = await loadRankerIdols(rowsMetadata);
      return buildRankerData(rankerMetadata, rowsMetadata, idolsMetadata);
    }
    return rankerMetadata;
  } catch (error) {
    return error;
  }
};

export const loadUserRankers = async (userId) => {
  try {
    const userRankersMetadata = [];

    const userRankerIds = await loadUserRankerIds(userId);
    if (!userRankerIds) {
      return null;
    }

    for (const id of userRankerIds) {
      const rankerMetadata = await loadRankerMetadata(id);
      if (rankerMetadata) {
        userRankersMetadata.push(rankerMetadata);
      }
    }

    return userRankersMetadata;
  } catch (error) {
    return console.error('Error Loading User Rankers', error);
  }
};

export const saveRankerData = async (originalRankerData, newRankerData) => {
  // console.log(originalRankerData, newRankerData);
  const deletedRowIds = [];

  for (const id of originalRankerData.rowOrder) {
    if (!newRankerData.rows[id]) {
      deletedRowIds.push(id);
      await deleteRow(id);
    }
  }

  await updateRankerMetadata(newRankerData.metadata);
  if (newRankerData.rowOrder.length) {
    await updateRankerRows(newRankerData);
    await updateRowsMetadata(newRankerData);
  }


  // console.log('Deleted row ids', deletedRowIds);
};
