/* eslint-disable no-nested-ternary */
import React from 'react';

import uuid from 'uuid/v4';

import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { DragDropContext } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import rankerTestData from 'testData/ranker-test-data';

import { startLoadRows, updateRanker } from 'actions/ranker';
import selectIdols from '../selectors/idols';
import { addIdolToRow, reorder } from '../helpers/order';

import Sidebar from './sidebar/Sidebar';
import RankerDroppable from './ranker/RankerDroppable';
import IdolList from './idol/IdolList/IdolList';
import LoadingIdolSekai from './LoadingIdolSekai';


export class DashboardPage extends React.Component {
  state = {}

  componentDidMount() {
    const { match: { params: { rankerId } } } = this.props;
    const { startLoadRows } = this.props;

    // console.log('Share Ranker id: ', this.props, rankerId);
    if (rankerId) {
      // This should only run when the user access a ranker
      // with a share link and therefore we can get the rankerId
      startLoadRows(rankerId);
    }
  }

  addIdolToRankerRow = (draggableId, ranker, destination) => {
    const { rows, idols } = ranker;
    const { idols: availableIdols, updateRanker } = this.props;

    // Generate unique ID for the idol to identify it in the ranker. This is not the idol ID
    const generatedIdolId = uuid();

    const foundIdol = availableIdols.find(idol => idol.id === draggableId);

    const previousIdolsInRow = ranker.rows[destination.droppableId].idolIds;


    const updatedIdolsInRow = addIdolToRow(
      previousIdolsInRow,
      generatedIdolId,
      destination.index
    );

    const updatedRow = {
      ...rows[destination.droppableId],
      idolIds: updatedIdolsInRow
    };

    const newRankerState = {
      ...ranker,
      idols: { ...idols, [generatedIdolId]: foundIdol },
      rows: {
        ...rows,
        [destination.droppableId]: updatedRow
      }
    };
    // console.log(`Dashboard reorder between rows
    // from idolList to ${destination.droppableId}, updated state: `, newRankerState);

    updateRanker(newRankerState);
  }

  reorderBetweenRows = (source, destination, draggableId, ranker, sourceRow, destinationRow) => {
    const { rows, metadata: { idolsPerRowLimit = 16 } } = ranker;
    const { updateRanker } = this.props;
    const previousIdolsInRow = ranker.rows[destination.droppableId].idolIds;

    if (previousIdolsInRow.length >= idolsPerRowLimit) {
      toast.error(`Limit: ${idolsPerRowLimit} idols per Row `, {
        position: 'bottom-center',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    // It the idol is being drag from the idol list to the ranker
    if (source.droppableId === 'idolList') {
      this.addIdolToRankerRow(draggableId, ranker, destination);
    } else {
      const sourceIdolIds = Array.from(sourceRow.idolIds);
      sourceIdolIds.splice(source.index, 1);
      const updatedSourceRow = {
        ...sourceRow,
        idolIds: sourceIdolIds
      };

      const destinationIdolIds = Array.from(destinationRow.idolIds);
      destinationIdolIds.splice(destination.index, 0, draggableId);
      const updatedDestinationRow = {
        ...destinationRow,
        idolIds: destinationIdolIds
      };

      const newRankerState = {
        ...ranker,
        rows: {
          ...rows,
          [updatedSourceRow.id]: updatedSourceRow,
          [updatedDestinationRow.id]: updatedDestinationRow
        }
      };
      // console.log(`Dashboard reorder between rows from ${source.droppableId}
      // to ${destination.droppableId}, updated state: `, newRankerState);
      updateRanker(newRankerState);
    }
  }

  reorderInsideRow = (source, destination, draggableId, ranker) => {
    const { rows } = ranker;
    const { updateRanker } = this.props;

    const row = rows[source.droppableId];
    const reorderedIdolIds = reorder(row.idolIds, source.index, destination.index);

    const updatedRow = {
      ...row,
      idolIds: reorderedIdolIds
    };

    const newRankerState = {
      ...ranker,
      rows: {
        ...rows,
        [updatedRow.id]: updatedRow
      }
    };

    // console.log('Dashboard reorder inside row idols: ', newRankerState);
    updateRanker(newRankerState);
  }

  onDragEnd = (result) => {
    // console.log('Dashboard drag n drop result', result);

    const { destination, source, draggableId } = result;
    const { ranker } = this.props;
    const { rows } = ranker;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.indexx) {
      return;
    }

    const sourceRow = rows[source.droppableId];
    const destinationRow = rows[destination.droppableId];

    // if it's a reorder inside the same row
    if (sourceRow === destinationRow) {
      this.reorderInsideRow(source, destination, draggableId, ranker);
    } else {
      // Moving from one list to another
      this.reorderBetweenRows(source, destination, draggableId, ranker, sourceRow, destinationRow);
    }
  }


  render() {
    const {
      loadingRows, selectedRanker, ranker, history, updateRanker
    } = this.props;

    return (

      <div className="flex-row-container idolsekai-background ">

        <DragDropContext onDragEnd={this.onDragEnd}>

          <div className="sticky-idol-list-container">
            <IdolList />
          </div>

          <div id="capture" className="sticky-ranker-container">
            {
              selectedRanker || ranker
                ? (!loadingRows
                  ? <RankerDroppable ranker={ranker} history={history} />
                  : <LoadingIdolSekai loadingClass="loading-ranker-container" size="medium" />
                )

                : (!loadingRows
                  ? (
                    <div style={{ marginTop: '30%', color: 'white', textAlign: 'center' }}>
                      <h1 style={{ fontFamily: "Lato', sans-serif" }}>Select a ranker </h1>
                      <Button onClick={() => updateRanker(rankerTestData)} color="twitter">See an example</Button>
                    </div>
                  )
                  : <LoadingIdolSekai loadingClass="loading-ranker-container" size="medium" />
                )
            }
          </div>

          <div className="sticky-sidebar-container">
            <Sidebar />
          </div>

        </DragDropContext>

      </div>


    );
  }
}


// export default DashboardPage;

const mapStateToProps = (state) => {
  // console.log('State:', state);
  return {
    idols: selectIdols(state.idol.allIdols, state.filters),
    loadingIdols: state.idol.loading,
    loadingRows: state.rankers.loadingRows,
    selectedRanker: state.rankers.selectedRanker,
    ranker: state.rankers.testRows
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLoadRows: (rankerId) => dispatch(startLoadRows(rankerId)),
  updateRanker: (ranker) => dispatch(updateRanker(ranker))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
