import loadIdolMetadata from '../idol/idol-metadata';
import removeDuplicates from '../../../helpers/remove-duplicates';

export const loadRankerIdols = async (rowsMetadata) => {
  const idolIds = [];
  const idolsMetadata = [];

  for (const rowMetadata in rowsMetadata) {
    const rowIdolIds = rowsMetadata[rowMetadata].idolIds;

    rowIdolIds.forEach(idolId => {
      idolIds.push(idolId);
    });
  }

  for (const id of removeDuplicates(idolIds)) {
    const idolMetadata = await loadIdolMetadata(id);

    if (idolMetadata) {
      idolsMetadata.push(idolMetadata);
    }
  }
  return idolsMetadata;
};

export default loadRankerIdols;
