import React from 'react';
import {
  Icon, Button, Modal
} from 'semantic-ui-react';

const Login = ({ startLogin }) => (
  <Modal trigger={<button type="button" className="button button--link" size="mini"> <Icon fitted name="sign in" />    Login</button>
}
  >
    <div className="box-layout">
      <div>
        <img height="200vh" src="/assets/logo.png" alt="IdolSekai" />
      </div>
      <div>
        <h4> Login with:</h4>
      </div>
      <br />
      <div>
        <Button disabled color="twitter">
          <Icon name="twitter" /> Twitter
        </Button>
        <Button onClick={startLogin} color="google plus">
          <Icon name="google plus" /> Google Plus
        </Button>
        <Button disabled color="facebook">
          <Icon name="facebook" /> Facebook
        </Button>
      </div>

    </div>
  </Modal>
);

export default Login;
