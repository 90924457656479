const authReducerDefaultState = {
  uid: null,
  user: {
    displayName: null,
    email: null,
    isAdmin: false,
    isBasic: true,
    isDonor: false,
    isMod: false,
    isVip: false,
    photoURL: null,
    uid: null
  }
};

export default (state = authReducerDefaultState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        uid: action.uid
      };

    case 'LOGOUT':
      return {
        ...state,
        uid: null,
        user: null
      };

    case 'UPDATE_USER_DATA':
      return {
        ...state,
        user: action.user
      };

    default:
      return state;
  }
};
