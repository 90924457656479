import React from 'react';
import AvatarName from './components/AvatarName';
import AvatarImage from './components/AvatarImage';
import AvatarNameStyled from './components/AvatarNameStyled';
import AvatarGroupLabel from './components/AvatarGroupLabel';
import AvatarPosition from './components/AvatarPosition';


export class JapaneseAvatar extends React.Component {
  state = {
    idol: null
  }

  componentDidMount() {
    const { idolData } = this.props;
    // console.log('Component Mount idol: ', idolData);
    this.setState({ idol: idolData });
  }

  shouldComponentUpdate(nextProps) {
    const { idol } = this.state;
    const { positionInRow, rankerOptions } = this.props;
    // console.log('Should update idol', nextProps.idolData, idol);
    if (rankerOptions.showIdolPosition !== nextProps.rankerOptions.showIdolPosition) {
      return true;
    }
    if (positionInRow && positionInRow !== nextProps.positionInRow) {
      return true;
    }
    if (nextProps.idolData === idol) {
      return false;
    }
    return true;
  }

  // canUseAkbFont = (groupName) => {
  //   const regex = /[^a-zA-Z0-9!@#$%^&*()-]/g;
  //   const nonSupportedChars = groupName.match(regex);
  //   if (nonSupportedChars) {
  //     return false;
  //   }
  //   return true;
  // }

  render() {
    // const profilesFolder = process.env.ASSETS_IDOL_PROFILES;
    const profilesFolder = 'https://idolsekai.s3.eu-central-1.amazonaws.com/Profiles/jpop';
    const {
      idolData: {
        gender,
        folderId,
        displayName,
        positions,
        type
      },
      // context,
      getBackgroundColor,
      getPortraitBackgroundUrl,
      getUniqueGroups,
      positionInRow,
      rankerOptions
    } = this.props;
    const nameStyled = displayName.leftSideName;
    const name = displayName.bottomName;
    const maxLabelsToDisplay = 2;

    return (
      <div className="idol-list-item-avatar-container">

        {rankerOptions.showIdolPosition
           && positionInRow && <AvatarPosition positionInRow={positionInRow} /> }

        <div className="idol-list-item-avatar-image-container ">

          <AvatarNameStyled nameStyled={nameStyled} />

          <AvatarImage
            displayName={name}
            folderId={folderId}
            gender={gender}
            groupPositionIndex={0}
            positions={positions}
            profilesFolder={profilesFolder}
            type={type}
            getBackgroundColor={getBackgroundColor}
            getPortraitBackgroundUrl={getPortraitBackgroundUrl}
          />

        </div>

        <div className="idol-list-item-avatar-groups-jpop">
          {getUniqueGroups(positions).map((group, index) => (
            index < maxLabelsToDisplay && (
            <AvatarGroupLabel
              key={group}
              gender={gender}
              group={group.includes('48') ? group.toUpperCase() : group}
              positions={positions}
              groupPositionIndex={index}
              type={type}
              getBackgroundColor={getBackgroundColor}
              getPortraitBackgroundUrl={getPortraitBackgroundUrl}
            />
            )
          )) }
        </div>

        <AvatarName name={name} />

      </div>
    );
  }
}


export default JapaneseAvatar;
