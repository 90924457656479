import React from 'react';
import uuid from 'uuid/v4';
import RankerRow from './RankerRow';


const RankerDnd = (props) => {
  // const { rankerTestData } = this.state;
  const {
    isEditable, ranker, rankerOptions, deleteIdol, deleteRow, updateRowMetadata
  } = props;
  // console.log('DND ranker', ranker);
  const { rows, rowOrder, idols } = ranker;

  return (
  // <div style={{ height: '400px' }}>
    <div className="ranker-rows-container">

      {rowOrder.map(rowId => {
        const row = rows[rowId];
        // row.idolIds.map(idolId => console.log('Dnd idol id', idolId));
        const idolsFound = row.idolIds.map(idolId => idols[idolId]);
        // console.log('DND', rows, row.id, idolsFound);
        if (row && row.id) {
          return (
            <RankerRow
              key={row.id}
              direction="horizontal"
              isEditable={isEditable}
              idols={idolsFound}
              deleteIdol={deleteIdol}
              deleteRow={deleteRow}
              rankerOptions={rankerOptions}
              row={row}
              updateRowMetadata={updateRowMetadata}
            />
          );
        }
        return (
          <div key={uuid()}>
            <h2> Row Data Corrupted. Please create a new Ranker.</h2>
          </div>
        );
      })}
    </div>

  );
};

export default RankerDnd;
