// Filters Reducer

const filtersReducerDefaultState = {
  text: '',
  age: [],
  birthPlace: [],
  country: [],
  gender: [],
  group: [],
  status: [],
  team: [],
  type: [],
  sortBy: {
    value: 'name',
    direction: 'ascending'
  }
};
export default (state = filtersReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_TEXT_FILTER':
      return {
        ...state,
        text: action.text
      };

      // case 'TOGGLE_CHECKBOX':
      //   return {
      //     ...state,
      //     checkboxes: state.checkboxes.map(checkbox => (checkbox.id === action.checkboxId
      //       ? { ...checkbox, enabled: !checkbox.enabled }
      //       : checkbox))
      //   };

    case 'RESET_FILTERS':
      return {
        ...filtersReducerDefaultState
      };

    case 'UPDATE_FILTER':
      return {
        ...state,
        [action.filters.filterName]: action.filters.filterValue
      };

    case 'UPDATE_SORT_BY_VALUE':
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          value: action.value
        }
      };

    case 'UPDATE_SORT_BY_DIRECTION':
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          direction: action.direction
        }
      };

    default:
      return state;
  }
};
