import React from 'react';
import { Icon } from 'semantic-ui-react';

const IdolCardInfo = (props) => {
  const {
    idolData: {
      age,
      birthPlace,
      status,
      wiki
    }
  } = props;
  const noDataText = 'No data';

  return (
    <div className="idol-list-item-avatar-details-container">

      <div className="idol-list-item-avatar-details-age">
        <Icon name="birthday cake" />{age || <i>{noDataText}</i>}
      </div>

      <div className="idol-list-item-avatar-details-location">
        <Icon name="map marker alternate" />{birthPlace || <i>{noDataText}</i>}
      </div>

      <div className="idol-list-item-avatar-details-status">
        <b>Status: </b>{status || <i>{noDataText}</i>}
      </div>

      <div className="idol-list-item-avatar-details-wiki">
        {
              wiki
                ? (
                  <a
                    href={`${wiki}`}
                    alt="wiki"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name="wikipedia w" fitted />iki
                  </a>
                )
                : (<div style={{ opacity: '0.4' }}><Icon name="wikipedia w" fitted />iki</div>)

          }
      </div>


    </div>
  );
};

export default IdolCardInfo;
