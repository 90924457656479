import React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/storage';
import {
  Button, Portal, Progress, Segment
} from 'semantic-ui-react';
import uuid from 'uuid/v4';
import html2canvas from 'html2canvas';

import { updateRankerOptions } from 'actions/ranker';
import DonateButton from '../shared/DonateButton';
import IdolsekaiClipboard from '../shared/IdolsekaiClipboard';

class RankerShare extends React.Component {
    state = {
      creatingImage: false,
      imageUrl: '',
      popupOpen: false,
      uploadProgress: 0
    };

    onOpen = () => {
      const { updateRankerOptions, rankerOptions } = this.props;
      // const url = `${window.location.host}/ranker/${rankerId}`;
      window.scrollTo(0, 0);

      this.setState({
        popupOpen: true
      });

      updateRankerOptions({
        ...rankerOptions,
        previewMode: true,
        showRankerLogo: true
      });
    }

    onClose = () => {
      const { updateRankerOptions, rankerOptions } = this.props;

      this.setState({
        creatingImage: false,
        imageUrl: '',
        popupOpen: false
      });

      updateRankerOptions({
        ...rankerOptions,
        previewMode: false,
        showRankerLogo: false
      });
    }

    onCreateImage = () => {
      this.setState({
        creatingImage: true
      });

      html2canvas(document.querySelector('#capture'), {
        backgroundColor: 'rgba(255, 177, 192, 1)',
        proxy: 'https://idolsekai-test.herokuapp.com/',
        // without scale 2, on 1080p resolution it would show a grey background on the rows
        scale: 2
      }).then((canvas) => {
        // document.body.appendChild(canvas);
        this.uploadImage(canvas);
      });
    }

    uploadImage = (canvas) => {
      const { updateRankerOptions, rankerOptions, userId } = this.props;
      // console.log('ENV', process.env);
      const storageBucketUrl = process.env.IDOLSEKAI_SCREENSHOT_STORAGE_BUCKET;

      canvas.toBlob((blob) => {
        const metadata = {
          contentType: 'image/jpg'
        };
        // Upload file and metadata to the object 'images/mountains.jpg'
        const generatedImageId = uuid();
        const uploadTask = firebase.storage().refFromURL(`gs://${storageBucketUrl}/${userId}/${generatedImageId}.jpg`).put(blob, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            const { popupOpen } = this.state;

            if (!popupOpen) {
              uploadTask.cancel();
              this.setState({
                uploadProgress: 0
              });

              updateRankerOptions({
                ...rankerOptions,
                previewMode: false,
                showRankerLogo: false
              });
            }

            // Get task progress, including the number of bytes
            // uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const percentage = Math.round(progress);
            // console.log(`Upload is ${percentage}% done`);

            this.setState({
              uploadProgress: percentage
            });
          }, (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;

              case 'storage/canceled':
                // User canceled the upload
                break;

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;

              default:
                break;
            }
          }, () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              // console.log('File available at', downloadURL);
              this.setState({
                creatingImage: false,
                imageUrl: `https://storage.googleapis.com/${storageBucketUrl}/${userId}/${generatedImageId}.jpg`
              });
            });
          });
      });
    }

    render() {
      const {
        creatingImage, imageUrl, uploadProgress
      } = this.state;
      const { rankerId } = this.props;
      const rankerUrl = `${window.location.host}/ranker/${rankerId}`;

      return (
        <Portal
          closeOnTriggerClick
          openOnTriggerClick
          trigger={<Button color="blue">Share / Screenshot</Button>}
          on="click"
          onOpen={this.onOpen}
          onClose={this.onClose}
        >
          <Segment style={{
            left: '50%', position: 'fixed', top: '40%', zIndex: 1000
          }}
          >

            <div>
              <h4>Ranker Link</h4>

              <div className="flex-row-container">
                <IdolsekaiClipboard defaultValue={rankerUrl} />
                <Button.Group>
                  <Button as="a" href={`https://${rankerUrl}`} target="_blank" icon="external" basic size="large" />
                </Button.Group>
              </div>

            </div>

            <div style={{ marginTop: '5%' }}>
              <h4>Ranker Image Link</h4>
              <div style={{ color: '#f2711c' }}>*If the screenshot has issues</div>
              <div style={{ color: '#f2711c', marginBottom: '5%' }}>try to resize your browser window.</div>

              {
              !imageUrl
              && (
                creatingImage
                  ? <Progress percent={uploadProgress} inverted color="blue" progress />

                  : (
                    <Button
                      color="twitter"
                      onClick={this.onCreateImage}
                    >Generate Image
                    </Button>
                  )
              )
            }

              {
              !creatingImage && imageUrl
              && (
              <div className="flex-row-container">
                <IdolsekaiClipboard defaultValue={imageUrl} />
                <Button.Group>
                  <Button as="a" href={imageUrl} target="_blank" icon="external" basic size="large" />
                </Button.Group>
              </div>
              )
            }

              <div style={{ marginTop: '5%' }}>
                <h4>Help Idolsekai stay alive!</h4>

                <div style={{ justifyContent: 'center' }} className="flex-row-container">
                  <DonateButton />
                </div>

              </div>
            </div>
          </Segment>
        </Portal>
      );
    }
}

// export default RankerShare;

const mapStateToProps = (state) => {
  // console.log('[RankerShare] State Rankers Reducer: ', state);
  return {
    rankerOptions: state.rankers.options,
    userId: state.auth.uid
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateRankerOptions: (rankerOptions) => dispatch(updateRankerOptions(rankerOptions))
});

export default connect(mapStateToProps, mapDispatchToProps)(RankerShare);
