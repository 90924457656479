import React from 'react';
import {
  Button
} from 'semantic-ui-react';
import RankerDelete from './RankerDelete';
import RankerShare from './RankerShare';
// import RankerOptions from './RankerOptions';

const RankerFooter = (props) => {
  const {
    deleteRanker, deletingRanker, rankerId, saveRanker, savingRanker
  } = props;

  return (
    <div className="ranker-footer-options-container">
      <div className="flex-column-container">

        {/* <RankerOptions /> */}
        <Button.Group>
          <Button color="green" onClick={saveRanker} loading={savingRanker} disabled={savingRanker}>Save</Button>
          <Button.Or />
          <RankerShare rankerId={rankerId} />
          <Button.Or />
          <RankerDelete
            deleteRanker={deleteRanker}
            loading={deletingRanker}
            disabled={deletingRanker}
          />

        </Button.Group>
      </div>

    </div>
  );
};

export default RankerFooter;
