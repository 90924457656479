/**
 * Idol UUID
 * * Ex: 8f79bb3c-0ea0-4265-89f2-230691cd1ca4
 * @param {string} id
 * @returns string
 */
const idDto = (id) => {
  return id || null;
};

export default idDto;
