import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import IdolAvatarDraggable from '../idol/IdolAvatar/IdolAvatarDraggable';
import RowHeader from './RowHeader';

const Container = styled.div`
  &:hover {
    margin: 1% 3% 1% 3%;
    // border: 1px solid lightgrey;
    // border-radius: 2px;
  }
  margin: 1% 3% 1% 3%;
  // border: 1px solid white;
  // border-radius: 2px;
`;
const RowIdolList = styled.div`
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);


  padding:8px;
  display: flex;
  flex-direction: row;
  min-height:150px;
  //overflow-x: auto;
  ${({ isPreviewMode }) => (isPreviewMode ? 'flex-wrap: wrap;' : 'overflow-x: auto;')};
  background-color: ${props => (props.isDraggingOver ? 'skyblue' : '#ffffff')}
`;


class RankerRow extends React.Component {
  getGeneratedIdolId = (index) => {
    const { row } = this.props;
    return row.idolIds[index];
  }

  getPositionInRow = (id) => {
    const { row } = this.props;
    return row.idolIds.indexOf(id) + 1;
  }

  render() {
    const {
      row, idols, direction, deleteIdol, deleteRow, isEditable,
      rankerOptions: { showRowNames, previewMode }, updateRowMetadata
    } = this.props;
    return (
      <Container>
        {
          showRowNames
          && (
          <RowHeader
            isEditable={isEditable}
            row={row}
            deleteRow={deleteRow}
            updateRowMetadata={updateRowMetadata}
          />
          )
        }


        <Droppable droppableId={row.id} direction={direction} type="IdolsekaDroppableType">
          { (provided, snapshot) => (
            <RowIdolList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
              isPreviewMode={previewMode}
            >
              {idols.map((idol, index) => (
                <div key={this.getGeneratedIdolId(index)} className="idol-avatar-draggable">
                  <IdolAvatarDraggable
                    context="ranker-row"
                    idol={idol}
                    index={index}
                    generatedIdolId={this.getGeneratedIdolId(index)}
                    rowId={row.id}
                    positionInRow={this.getPositionInRow(this.getGeneratedIdolId(index))}
                    deleteIdol={deleteIdol}
                  />
                </div>
              ))
              }
              {provided.placeholder}
            </RowIdolList>
          ) }

        </Droppable>
      </Container>

    );
  }
}

export default RankerRow;
