import React from 'react';

// import IdolAvatar from '../IdolAvatar';
import IdolAvatarDroppable from '../IdolAvatar/IdolAvatarDroppable';
import IdolCardInfo from './IdolCardInfo';
// import IdolCardPicker from './IdolCardPicker';


const IdolCard = (
  { idolID: id, idolData: idol, idolIndex: index }
) => (
  <div className="idol-list-item">
    <IdolAvatarDroppable idol={idol} index={index} />

    <IdolCardInfo idol={idol} />

    {/* <IdolCardPicker idolID={id} idolData={idol} /> */}
  </div>
);

export default IdolCard;
