import React from 'react';

const LoadingPage = () => (
  <div className="loader">
    {/* <img alt="loader" className="loader__image" src="/images/loader.gif" /> */}
    <img alt="loader" className="loader__image loading-large" src="https://idolsekai.s3.eu-central-1.amazonaws.com/Assets/loading.png" />
  </div>
);

export default LoadingPage;
