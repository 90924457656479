/* eslint-disable no-nested-ternary */
import React from 'react';

import uuid from 'uuid/v4';
import { Button } from 'semantic-ui-react';
import RankerCard from './RankerCard';


const RankerList = ({
  loading,
  onCreateRanker, selectedRanker, sidebarPlaceholder, sortedRankers, onSelectRanker,
  isUnderRankerLimit
}) => {
  return (
    <div>
      <div className="sidebar-new-row-container">
        <div className={`sidebar-new-row-icon ${!isUnderRankerLimit && 'idolsekai-disabled-opacity '}`}>
          <Button
            loading={loading}
            disabled={loading}
            icon="plus"
            style={{ backgroundColor: '#55acee' }}
            circular
            size="mini"
            onClick={onCreateRanker}
          />
        </div>
        <div className="sidebar-new-row-label">
          <h3 className="idolsekai-default-font isk-medium-font">New Ranker</h3>
        </div>
      </div>
      <div className="sidebar-rows-container">
        {
          sortedRankers
            ? sortedRankers.length > 0
              ? (
                <Button.Group vertical>
                  {
                    sortedRankers.map((ranker) => {
                      return (
                        <RankerCard
                          key={uuid()}
                          ranker={ranker}
                          selectedRanker={selectedRanker}
                          onSelectRanker={onSelectRanker}
                        />

                      );
                    })
                  }
                </Button.Group>
              )
              : sidebarPlaceholder


            : <h4>No Rankers Found</h4>
        }
      </div>

    </div>
  );
};

export default RankerList;
