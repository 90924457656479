import { firebase } from '../../firebase';


export const deleteRanker = async (ranker) => {
  const rankerId = ranker.metadata.id;

  return firebase.database().ref(`rankers/${rankerId}`).set(null)
    .then((snapshot) => {
      // console.log('Deleted ranker:', rankerId);
      // const updatedRowId = snapshot.key;
      // return updatedRowId;
    })
    .catch((error) => {
      // console.log('Failed to delete ranker: ', error);
    });
};

export default deleteRanker;
