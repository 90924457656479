import React from 'react';
import {
  Image
} from 'semantic-ui-react';
import uuid from 'uuid/v4';
import { removeSpecialCharacters } from 'helpers';

import IdolCardDetails from './IdolCardDetails';

const logosFolder = 'https://idolsekai.s3.eu-central-1.amazonaws.com/Logos';

export const JapaneseCardInfo = ({
  idolData,
  idolData: {
    positions
  }
}) => (

  <div className="idol-list-item-info-container">


    <div className="idol-list-item-group-container">
      {
          positions.map((position) => {
            // console.log('html position', position);
            return (
              <div key={uuid()} className="idol-list-item-group-jpop">
                <div className="idol-list-item-group-logo idolsekai-shadow-soft">
                  <Image
                    fluid
                    src={encodeURI(`${logosFolder}/${removeSpecialCharacters(position.groupName)}Logo.jpg`)}
                  />
                </div>
                {
                  position.teamName !== 'null' && position.teamName !== undefined
                    && (
                      <div
                        style={{
                          border: `2px solid ${position.teamColor !== 'null' && position.teamColor !== undefined ? position.teamColor : '#e5e5e5'}`,
                          backgroundColor: `${position.teamColor !== 'null' && position.teamColor !== undefined ? position.teamColor : '#e5e5e5'}`,
                          color: 'white',
                          fontWeight: 800,
                          textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'

                        }}
                        className="idol-list-item-group-team idolsekai-shadow-soft"
                      >
                        {position.teamName}
                      </div>
                    )
                }
              </div>
            );
          })
        }
    </div>


    <IdolCardDetails idolData={idolData} />

  </div>
);


export default JapaneseCardInfo;
