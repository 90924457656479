/**
 * Name in localized writing like Kanji or Hangul
 * * Ex: 최종현
 * @param {string} nameStyled
 * @returns string
 */
const nameStyledDto = (nameStyled) => {
  return nameStyled ? nameStyled.trim() : '';
};

export default nameStyledDto;
