/**
 * Nickname in localized writing like Kanji or Hangul
 * * Ex: 창조
 * @param {string} nicknameStyled
 * @returns string
 */
const nicknameStyledDto = (nicknameStyled) => {
  return nicknameStyled ? nicknameStyled.trim() : '';
};

export default nicknameStyledDto;
