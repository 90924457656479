/* eslint-disable no-nested-ternary */
import React from 'react';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  startCreateRanker, startLoadUserRankers, startLoadRows, selectRanker
} from 'actions/ranker';

import { sortByDate } from 'helpers';
import RankerOptions from '../ranker/RankerOptions';
import LoadingIdolSekai from '../LoadingIdolSekai';
import Credits from '../Credits';
import RankerList from '../ranker/RankerList';
import DonateButton from '../shared/DonateButton';


class Sidebar extends React.Component {
    state = {
      rankerLimit: 4
    }

    componentWillMount() {
      const { startLoadUserRankers, user } = this.props;
      if (user) {
        startLoadUserRankers();
      }
    }

    componentDidUpdate(prevProps) {
      // const { selectedRanker, startLoadRows } = this.props;
      // if (selectedRanker && selectedRanker !== prevProps.selectedRanker) {
      //   startLoadRows(selectedRanker.id);
      // }
    }

    isUnderRankerLimit = () => {
      const { rankers } = this.props;
      const { rankerLimit } = this.state;

      return rankers.length < rankerLimit;
    }

    onCreateRanker = () => {
      const { startCreateRanker } = this.props;
      const { rankerLimit } = this.state;

      if (!this.isUnderRankerLimit()) {
        toast.error(`Limit: ${rankerLimit} rankers`, {
          position: 'bottom-center',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }
      startCreateRanker(rankerLimit);
    }

    onSelectRanker = (ranker) => {
      const { startLoadRows, history } = this.props;
      // selectRanker(ranker.id);
      if (history) {
        history.push('/');
      }
      startLoadRows(ranker.id);
    }


    pickSidebarPlaceholder = (loading, rankers) => {
      let sidebarPlaceholder;
      if (loading) {
        sidebarPlaceholder = (
          // <Loader active inline="centered">Loading</Loader>
          <LoadingIdolSekai size="small" />
        );
      } else if (rankers.length === 0 && !loading) {
        sidebarPlaceholder = (
          <h4>No Rankers found</h4>
        );
      }
      return sidebarPlaceholder;
    };

    render() {
      const {
        loading, selectedRanker, rankers, user
      } = this.props;
      const isUnderRankerLimit = this.isUnderRankerLimit();
      const sortedRankers = sortByDate(rankers);
      // console.log('Rankers list: ', rankers, sortedRankers);
      const sidebarPlaceholder = this.pickSidebarPlaceholder(loading, sortedRankers);

      return (
        <div className="sidebar-container">
          {
            user
              ? (
                <RankerList
                  loading={loading}
                  selectedRanker={selectedRanker}
                  sortedRankers={sortedRankers}
                  onCreateRanker={this.onCreateRanker}
                  sidebarPlaceholder={sidebarPlaceholder}
                  onSelectRanker={this.onSelectRanker}
                  isUnderRankerLimit={isUnderRankerLimit}
                />
              )
              : <h3>Please login to create and share rankers!</h3>
          }


          <RankerOptions />

          <Credits />

          <div style={{ marginTop: '5%', marginBottom: '3%', textAlign: 'center' }}>
            <h4 className="idolsekai-default-font isk-small-font">Help Idolsekai stay alive!</h4>
            <DonateButton />
          </div>

        </div>

      );
    }
}

const mapStateToProps = (state) => {
  // console.log('[Sidebar] State Rankers Reducer:', state);
  return {
    creatingRanker: state.rankers.creatingRanker,
    loading: state.rankers.loading,
    rankers: state.rankers.rankers,
    selectedRanker: state.rankers.selectedRanker,
    user: state.auth.uid
  };
};

const mapDispatchToProps = (dispatch) => ({
  startCreateRanker: (rankerLimit) => dispatch(startCreateRanker(rankerLimit)),
  startLoadUserRankers: () => dispatch(startLoadUserRankers()),
  startLoadRows: (rankerId) => dispatch(startLoadRows(rankerId)),
  selectRanker: (ranker) => dispatch(selectRanker(ranker))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
