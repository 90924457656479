/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { connect } from 'react-redux';
import {
  Accordion, Button, Icon, Input, Dropdown, Popup
} from 'semantic-ui-react';
import { DebounceInput } from 'react-debounce-input';
import {
  setTextFilter, toggleCheckbox, updateFilters, updateSortByValue, updateSortByDirection
} from 'actions/filters';

export class IdolListFilters extends React.Component {
  state = {
    accordions: {
      filter: false,
      sort: false
    },
    sortOptions: [
      {
        key: 'name',
        text: 'Name',
        value: 'name'
      },
      {
        key: 'age',
        text: 'Age',
        value: 'age'
      }
    ]

  }

  handleChange = (e, { name, value }) => {
    // console.log('Drop change', name, value);
    const { updateFilters } = this.props;

    updateFilters({ filterName: name, filterValue: value });
  }

  handleSortDirectionChange = (e, { name, value }) => {
    // console.log('Sort Drop Direction change', name, value);
    const { updateSortByDirection, filters: { sortBy } } = this.props;
    const direction = sortBy.direction === 'ascending' ? 'descending' : 'ascending';

    updateSortByDirection(direction);
  }

  handleSortValueChange = (e, { name, value }) => {
    // console.log('Sort Drop change', name, value);
    const { updateSortByValue } = this.props;

    updateSortByValue(value);
  }

  handleClick = (e, titleProps) => {
    const { name } = titleProps;
    const { accordions } = this.state;

    this.setState({
      accordions: {
        ...accordions,
        [name]: !accordions[name]
      }
    });
  }

  onTextChange = (e) => {
    const { setTextFilter } = this.props;
    setTextFilter(e.target.value.trim());
  };

  onCheckboxChange = (e) => {
    const { toggleCheckbox } = this.props;
    // console.log('Checkbox', e.target.id);
    toggleCheckbox(e.target.id);
  }

  render() {
    const {
      listIsEmpty, listIsLoading, idolFilterOptions, filters
    } = this.props;
    const { accordions, sortOptions } = this.state;
    // console.log('Filters', listIsLoading, listIsEmpty);

    return (
      <div>

        <Popup
          open={!listIsLoading && listIsEmpty}
          position="right center"
          flowing
          hoverable
          trigger={(
            <DebounceInput
              element={Input}
              fluid
              minLength={0}
              debounceTimeout={500}
              icon="search"
              type="text"
              value={filters.text}
              placeholder="Search idols"
              onChange={this.onTextChange}
            />
          )}
        >
          <div><b>Tip:</b> Use the filters or a different spelling like Kanji, Hangul,
          Stage Name or Nickname:
            <br />
            <br />
            Minju -><b> Minjoo (Different Spelling) </b>or<b> 김민주 (Hangul Name)</b>
            <br />
            Lee Jieun -><b> IU (Stage Name) </b>or<b> 아이유 (Hangul Nickname)</b>
            <br />
            Oguri Yui -><b> Yuiyui(Nickname) </b>or<b> 小栗 有以 (Kanji Name)</b>
          </div>
        </Popup>


        {
        idolFilterOptions
        && (
          <div>
            <Accordion>
              <Accordion.Title active={accordions.filter} index={0} name="filter" onClick={this.handleClick}>
                <Icon name="dropdown" />
          Filters
              </Accordion.Title>
              <Accordion.Content active={accordions.filter}>
                <div className="flex-row-wrap-container">

                  <div className="idol-filter-dropdown">
                    <Dropdown placeholder="Age" name="age" fluid clearable search multiple selection options={idolFilterOptions.ages} onChange={this.handleChange} value={filters.age} />
                  </div>

                  <div className="idol-filter-dropdown">
                    <Dropdown placeholder="BirthPlace" name="birthPlace" fluid clearable search multiple selection options={idolFilterOptions.birthPlaces} onChange={this.handleChange} value={filters.birthPlace} />
                  </div>

                  <div className="idol-filter-dropdown">
                    <Dropdown placeholder="Country" name="country" fluid clearable search multiple selection options={idolFilterOptions.countries} onChange={this.handleChange} value={filters.country} />
                  </div>

                  <div className="idol-filter-dropdown">
                    <Dropdown placeholder="Gender" name="gender" fluid clearable search multiple selection options={idolFilterOptions.genders} onChange={this.handleChange} value={filters.gender} />
                  </div>

                  <div className="idol-filter-dropdown">
                    <Dropdown placeholder="Group" name="group" fluid clearable search multiple selection options={idolFilterOptions.groups} onChange={this.handleChange} value={filters.group} />
                  </div>

                  <div className="idol-filter-dropdown">
                    <Dropdown placeholder="Status" name="status" fluid clearable search multiple selection options={idolFilterOptions.status} onChange={this.handleChange} value={filters.status} />
                  </div>

                  <div className="idol-filter-dropdown">
                    <Dropdown placeholder="Team" name="team" fluid clearable search multiple selection options={idolFilterOptions.teams} onChange={this.handleChange} value={filters.team} />
                  </div>

                  <div className="idol-filter-dropdown">
                    <Dropdown placeholder="Type" name="type" fluid clearable search multiple selection options={idolFilterOptions.types} onChange={this.handleChange} value={filters.type} />
                  </div>

                </div>
              </Accordion.Content>
            </Accordion>
            <Accordion>
              <Accordion.Title active={accordions.sort} index={1} name="sort" onClick={this.handleClick}>
                <Icon name="dropdown" />
        Sort by
              </Accordion.Title>
              <Accordion.Content active={accordions.sort}>
                <div className="flex-row-wrap-container">

                  <div className="idol-sort-dropdown">
                    <Dropdown
                      onChange={this.handleSortValueChange}
                      options={sortOptions}
                      placeholder="SortBy"
                      selection
                      value={filters.sortBy.value}
                    />

                  </div>
                  <Button
                    icon={`sort content ${filters.sortBy.direction}`}
                    size="small"
                    color="teal"
                    onClick={this.handleSortDirectionChange}
                  />
                </div>
              </Accordion.Content>
            </Accordion>
          </div>

        )
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.filters,
  idolFilterOptions: state.idol.filters
});

const mapDispatchToProps = (dispatch) => ({
  setTextFilter: (text) => dispatch(setTextFilter(text)),
  toggleCheckbox: (checkboxId) => dispatch(toggleCheckbox(checkboxId)),
  updateFilters: (filters) => dispatch(updateFilters(filters)),
  updateSortByValue: (value) => dispatch(updateSortByValue(value)),
  updateSortByDirection: (direction) => dispatch(updateSortByDirection(direction))
});

export default connect(mapStateToProps, mapDispatchToProps)(IdolListFilters);
