import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Ranker from './Ranker';

const RankerDroppable = ({ ranker, history }) => {
  return (
    <div className="ranker-container">
      <Droppable droppableId="droppable-2">
        {(provided, snapshot) => (
          <div
            className="full-height"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <Ranker ranker={ranker} history={history} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>

    </div>
  );
};

export default RankerDroppable;
