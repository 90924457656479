import uuid from 'uuid/v4';

const generateNewIdolIds = (rankerData) => {
  let generatedIdols = {};
  let newRankerData = rankerData;

  if (rankerData.rows) {
    Object.keys(rankerData.rows).forEach(row => {
      const { idolIds } = rankerData.rows[row];

      for (let index = 0; index < idolIds.length; index += 1) {
        const generatedIdolId = uuid();

        // If idol id exists (Might have been deleted from idols db)
        const idolExists = rankerData.idols[idolIds[index]];

        if (idolExists) {
          // Save idol metadata to create a new idol
          const idolMetadata = rankerData.idols[idolIds[index]];
          // Replace the current idol id with a new one
          newRankerData.rows[row].idolIds[index] = generatedIdolId;

          // Add new generated idol
          generatedIdols = {
            ...generatedIdols,
            [generatedIdolId]: idolMetadata
          };
        }
      }
    });
  }

  newRankerData = {
    ...rankerData,
    idols: generatedIdols
  };
  return newRankerData;
};

const buildRankerData = (rankerMetadata, rowsMetadata, idolsMetadata) => {
  let rankerData = {
    idols: null,
    rows: null,
    rowOrder: [],
    metadata: rankerMetadata
  };

  rowsMetadata.forEach(rowMetadata => {
    rankerData.rowOrder.push(rowMetadata.id);
    rankerData = {
      ...rankerData,
      rows: {
        ...rankerData.rows,
        [rowMetadata.id]: rowMetadata
      }
    };
  });

  idolsMetadata.forEach(idolMetadata => {
    rankerData = {
      ...rankerData,
      idols: {
        ...rankerData.idols,
        [idolMetadata.id]: idolMetadata
      }
    };
  });

  return generateNewIdolIds(rankerData);
};


export default buildRankerData;
